import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../Component/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import api from "../../../Service/Axios";
import { industries_list, subscriptions_list, signup } from "../../../Service/ApiUrls";
import Validation from "../Settings/utils/SignupValidations";
import "../../../assets/Css/style.css";
import "../../../assets/Css/signup.css";
import whitebg from "../../../assets/image/whitebg.svg";

function Signup() {
    const navigate = useNavigate();
    const [industries, setIndustries] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false); // Checkbox state

    const [formData, setFormData] = useState({
        client_name: "",
        email_id: "",
        mobile_no: "",
        password: "",
        industry_id: "",
        subscription_id: "",
        registration_type: "web",
    });

    const [touched, setTouched] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { errors } = Validation({ formData, touched }); // Validation function

    useEffect(() => {
        const fetchIndustries = async () => {
            try {
                const response = await api.get(industries_list);
                const industriesData = response.data?.data?.industries || [];
                setIndustries(industriesData);
            } catch (error) {
                console.error("Error fetching industries:", error);
            }
        };

        const fetchSubscriptions = async () => {
            try {
                const response = await api.get(subscriptions_list);
                const subscriptionsData = response.data?.data.subscriptions || [];
                setSubscriptions(subscriptionsData);
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            }
        };

        fetchIndustries();
        fetchSubscriptions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched((prev) => ({ ...prev, [name]: true }));
    };

    const togglePasswordVisibility = () => setShowPassword(!showPassword);
    const toggleConfirmPasswordVisibility = () =>
        setShowConfirmPassword(!showConfirmPassword);

    const handleCheckboxChange = (e) => {
        setCheckboxChecked(e.target.checked);
    };

 
    const handleSignup = async (e) => {
        e.preventDefault();
    
        // Mark all fields as touched
        setTouched({
            client_name: true,
            email_id: true,
            mobile_no: true,
            password: true,
            industry_id: true,
            subscription_id: true,
        });
    
        // Check if any form field is empty
        const isFormEmpty = Object.values(formData).some((value) => value === '');
        if (isFormEmpty) {
            toast.error("Please fill all required fields.");
            return;
        }
    
        // Check for validation errors
        const hasErrors = Object.values(errors).some((error) => error);
        if (hasErrors) {
            toast.error("Please correct the highlighted errors.");
            return;
        }
    
        // Proceed with API call if no errors
        setLoading(true);
    
        try {
            const response = await api.post(signup, formData);
            console.log("Signup successful:", response);
            toast.success("Signup successful! Redirecting to login...");
            
            // Redirect after success
            setTimeout(() => {
                setLoading(false); // Ensure the loader stops before redirection
                navigate("/login");
            }, 2000);
        } catch (error) {
            console.error("Signup error:", error);
    
            // Stop the loader
            setLoading(false);
    
            // Handle error response and show error messages in toast
            if (error.response && error.response.data) {
                const errorData = error.response.data;
    
                // Display each field-specific error
                Object.keys(errorData).forEach((field) => {
                    const errorMessage = errorData[field].join(", "); // Combine multiple error messages
                    toast.error(`${field}: ${errorMessage}`);
                });
            } else {
                toast.error("Signup failed! Please try again.");
            }
        }
    };
    
    
    
    

    const handleLoginNavigation = () => {
        setLoading(true); // Set loading to true when the user clicks the "Sign Up" button
        setTimeout(() => {
            navigate("/login");
        }, 2000); // Simulate loading for 2 seconds before navigating
    };


    return (
        <Container className="signup-container d-flex align-items-center justify-content-center">
            {loading ? (
                <Loader />
            ) : (
                <Row className="signup-box shadow p-4">
                    <Col md={6} className="signup-form">
                        <div className="form-class-signup">
                            <img src={whitebg} alt="logo" className="signup-logo mb-3" />
                            <div className="mt-3">
                                <label className="signup-label">Name</label>
                                <input
                                    type="text"
                                    name="client_name"
                                    placeholder="Enter your Full Name"
                                    className="signup-input text-area-signup"
                                    value={formData.client_name}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                />
                                {errors.client_name && <div className="error-message">{errors.client_name}</div>}
                            </div>
                            <div className="mt-3">
                            <label className="signup-label">Mobile Number</label>

                                <input
                                    type="text"
                                    name="mobile_no"
                                    placeholder="Mobile Number"
                                    className="signup-input text-area-signup"
                                    value={formData.mobile_no}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                />
                                {errors.mobile_no && <div className="error-message">{errors.mobile_no}</div>}
                            </div>
                            <div className="mt-3">
                                <label className="signup-label">Industry</label>
                                <select
                                    name="industry_id"
                                    className="signup-input text-area-signup"
                                    value={formData.industry_id}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Industry</option>
                                    {industries.map((industry) => (
                                        <option key={industry.id} value={industry.id}>
                                            {industry.industry_name}
                                        </option>
                                    ))}
                                </select>
                                {errors.industry_id && <div className="error-message">{errors.industry_id}</div>}

                            </div>

                            <div className="mt-3">
                                <label className="signup-label">Subscription Type</label>
                                <select
                                    name="subscription_id"
                                    className="signup-input text-area-signup"
                                    value={formData.subscription_id}
                                    onChange={handleInputChange}
                                >
                                    <option value="">Select Subscription Type</option>
                                    {subscriptions.map((subscription) => (
                                        <option key={subscription.id} value={subscription.id}>
                                            {subscription.subscription_name}
                                        </option>
                                    ))}
                                </select>
                                {errors.subscription_id && <div className="error-message">{errors.subscription_id}</div>}

                            </div>
                            <div className="mt-3">
                                <label className="signup-label">Email</label>

                                <input
                                    type="email"
                                    name="email_id"
                                    placeholder="Email Address"
                                    className="signup-input text-area-signup"
                                    value={formData.email_id}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email_id && <div className="error-message">{errors.email_id}</div>}
                            </div>
                            <div className="mt-3">
                                <label className="signup-label">Password</label>
                                <div className="position-relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                        className="signup-input text-area-signup"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        onBlur={handleBlur}
                                    />
                                    <span className="password-toggle-icon-signup" onClick={togglePasswordVisibility}>
                                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                                    </span>
                                    {errors.password && <div className="error-message">{errors.password}</div>}

                                </div>
                            </div>

                            {/* New Checkbox */}
                            <div className="mt-3">
                                <input
                                    type="checkbox"
                                    id="agreeCheckbox"
                                    checked={checkboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor="agreeCheckbox" className="ms-2" style={{ display: "inline", fontSize: "14px" }}>
                                    By signing up, you agree to our Terms & Conditions and Privacy Policy
                                </label>
                            </div>

                            <Button type="button" className="btn_signup w-100 mt-3" onClick={handleSignup}>
                                Sign Up
                            </Button>
                        </div>
                    </Col>

                    <Col md={6} className="signup-info d-flex flex-column align-items-start justify-content-center p-3">
                        <h5 className="signup-title">Already have an Existing account?</h5>
                        <p className="signup_subHead color-gray">
                            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do eiusmod tempor incididunt.

                        </p>
                        <button className="btn-log" onClick={handleLoginNavigation}>Login</button>
                    </Col>
                </Row>
            )}
        </Container>
    );
}

export default Signup;
