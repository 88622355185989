import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { LiaCopy } from "react-icons/lia";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";

export default function AllList() {
  const [copied, setCopied] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    // Replace with your API endpoint
    axios.get("YOUR_API_ENDPOINT_HERE")
      .then((response) => {
        if (response.data && Array.isArray(response.data)) {
          setData(response.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setData([]); // Set an empty array on error
      });
  }, []);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>Group Code Name</th>
            <th>Group Code Url</th>
            <th>Name of QR Codes</th>
            <th>Description</th>
            <th>No.of QR Codes</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((value, key) => (
              <tr key={key}>
                <td className="f-16 fw-500 tableText">{value.codename}</td>
                <td className="f-16 fw-400 tableText">
                  {value.codeurl}
                  <CopyToClipboard text={value.codeurl} onCopy={handleCopy}>
                    <span style={{ cursor: "pointer" }}>
                      <LiaCopy />
                      {copied && (
                        <span className="tooltip">Copied!</span>
                      )}
                    </span>
                  </CopyToClipboard>
                </td>
                <td className="f-16 fw-400 tableText">{value.name}</td>
                <td className="f-16 fw-400 tableText">{value.description}</td>
                <td className="f-16 fw-400 tableText">{value.noofcode}</td>
                <td className="f-16 fw-400 tableText">
                  <small className={`status-${value.status.toLowerCase()}`}>
                    {value.status}
                  </small>
                </td>
                <td>
                  <Link to="/qrcode/groupcodeview">View</Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
