import React, { useState, useEffect } from "react";
import { CopyAll } from "@mui/icons-material";
import axios from "axios";
import vector from "../../../../assets/image/template/Vector.png";

const ViewMoreLanding = ({ id, customLinks }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (id) {
      // Fetch impression count when the component mounts
      const fetchImpressionCount = async () => {
        try {
          const response = await axios.get(
            `https://web.linkoo.io:8001/api/poster/impression/${id}`
          );
          if (response?.data?.data?.count) {
            console.log("Impression Count:", response.data.data.count);
          } else {
            console.log("No impression count available for the provided ID.");
          }
        } catch (error) {
          console.error("Error fetching impression count:", error);
        }
      };

      fetchImpressionCount();
    }
  }, [id]);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const copyToClipboard = (link) => {
    navigator.clipboard.writeText(link).then(() => {
      alert("Link copied to clipboard!");
    });
  };

  const styles = {
    button: {
      padding: "10px 20px",
      backgroundColor: "rgb(212 54 197)",
      color: "#fff",
      border: "none",
      borderRadius: "35px",
      cursor: "pointer",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      width: "400px",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    closeButton: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: "20px",
      cursor: "pointer",
    },
    linkContainer: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      marginBottom: "10px",
    },
    linkInput: {
      flex: 1,
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    copyButton: {
      padding: "10px",
      backgroundColor: "rgb(220 55 204)",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  return (
    <div>
      <button style={styles.button} onClick={toggleModal}>
        View More <img src={vector} alt="Vector Icon" />
      </button>

      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <div style={styles.modalHeader}>
              <h3>Custom Links</h3>
              <button style={styles.closeButton} onClick={toggleModal}>
                &times;
              </button>
            </div>
            {customLinks && customLinks.length > 0 ? (
              customLinks.map((linkItem, index) => (
                <div key={linkItem.id} style={styles.linkContainer}>
                  <input
                    type="text"
                    readOnly
                    value={linkItem.link}
                    style={styles.linkInput}
                  />
                  <button
                    style={styles.copyButton}
                    onClick={() => copyToClipboard(linkItem.link)}
                  >
                    <CopyAll />
                  </button>
                </div>
              ))
            ) : (
              <p>No custom links available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewMoreLanding;
