import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import bin from "../../../../assets/image/bin.svg";
import edit from "../../../../assets/image/edit.svg";
import api from "../../../../Service/Axios";
import { roles, delete_user, role_edit, role_delete } from "../../../../Service/ApiUrls";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import CreateRole from "./CreateRole";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function RolesTable({ searchTerm }) {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editRoleData, setEditRoleData] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [roleIdToDelete, setRoleIdToDelete] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get(roles);
        setUserData(response.data?.data?.roles || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error("Failed to fetch roles. Please try again later.");
        setLoading(false);
      }
    };

    fetchRoles();
  }, []);

  const filteredUserData = userData.filter((user) =>
    user.role_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEdit = async (roleId) => {
    try {
      const response = await api.get(`${role_edit}/${roleId}`);
      setEditRoleData(response.data?.data?.role_detail || null);
      setShowModal(true);
      // toast.success("Role data fetched successfully for editing!");
    } catch (error) {
      console.error("Error fetching role details:", error);
      toast.error("Failed to fetch role details. Please try again later.");
    }
  };

  // const handleDelete = async (roleId) => {
  //   try {
  //     const response = await api.get(`${delete_user}/${roleId}`);
  //     if (response.status === 200) {
  //       setUserData((prevData) => prevData.filter((user) => user.id !== roleId));
  //       toast.success("Role deleted successfully!");
  //     }
  //   } catch (error) {
  //     console.error("Error deleting role:", error);
  //     toast.error("Failed to delete role. Please try again later.");
  //   }
  // };

  const handleDeleteClick = (roleId) => {
    setRoleIdToDelete(roleId);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await api.get(`${role_delete}/${roleIdToDelete}`);
      if (response.status === 200) {
        setUserData((prevData) => prevData.filter((user) => user.id !== roleIdToDelete));
        toast.success("Role deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting role:", error);
      toast.error("Failed to delete role. Please try again later.");
    }
    setShowDeleteDialog(false);
  };

  const handleAccessChange = (userId, accessType, value) => {
    setUserData((prevData) =>
      prevData.map((user) =>
        user.id === userId ? { ...user, [accessType]: value } : user
      )
    );
  };

  return (
    <div className="custom-table mt-3">
      <style jsx>{`
        .custom-table {
          padding: 10px;
        }
        th,
        td {
          white-space: nowrap;
          text-align: center;
          padding: 10px;
        }
        img.cursor {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
        .status-active {
          color: green;
        }
        .status-inactive {
          color: red;
        }
      `}</style>

      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: "justify" }}>Role</th>
            <th>Full Access</th>
            <th>Edit Access</th>
            <th>View Only Access</th>
            <th>Creation Date</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7" className="text-center">
                <CircularProgress sx={{ color: "pink" }} thickness={4} size={50} />
              </td>
            </tr>
          ) : (
            filteredUserData.map((user) => (
              <tr key={user.id}>
                <td style={{ textAlign: "justify" }}>{user.role_name}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.full_access === 1}
                    onChange={(e) =>
                      handleAccessChange(user.id, "full_access", e.target.checked ? 1 : 0)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.edit_access === 1}
                    onChange={(e) =>
                      handleAccessChange(user.id, "edit_access", e.target.checked ? 1 : 0)
                    }
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={user.view_access === 1}
                    onChange={(e) =>
                      handleAccessChange(user.id, "view_access", e.target.checked ? 1 : 0)
                    }
                    disabled
                  />
                </td>
                <td>{new Date(user.created_at).toLocaleDateString()}</td>
                <td>
                  <small
                    className={
                      user.active_status === 1 ? "status-active" : "status-inactive"
                    }
                  >
                    {user.active_status === 1 ? "Active" : "Inactive"}
                  </small>
                </td>
                <td>
                  <img
                    src={edit}
                    alt="Edit"
                    className="mt-3 me-3 cursor"
                    onClick={() => handleEdit(user.id)}
                  />
                  <img
                    src={bin}
                    alt="Delete"
                    className="mt-3 cursor"
                    onClick={() => handleDeleteClick(user.id)}                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this role?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} style={{background: "#d435c5",border: "none", color: "#fff"}} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {showModal && (
        <CreateRole
          show={showModal}
          setShow={setShowModal}
          editData={editRoleData}
        />
      )}
    </div>
  );
}

export default RolesTable;
