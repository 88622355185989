import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import vector from "../../../../assets/image/template/Vector.png"


const AppointmentModal = ({id}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        full_name: "",
        email_id: "",
        mobile_no: "",
        city: "",
        date: "",
        time: "",
        date_and_time: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
    const navigate = useNavigate();

    const toggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleDateTimeChange = (field, value) => {
        setFormData((prevData) => {
            const updatedData = { ...prevData, [field]: value };
            if (updatedData.date && updatedData.time) {
                updatedData.date_and_time = `${updatedData.date} ${updatedData.time}`;
            }
            return updatedData;
        });
    };
    useEffect(() => {
        if (id) {
            // Fetch impression count when the component mounts
            const fetchImpressionCount = async () => {
                try {
                    const response = await axios.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                    if (response?.data?.data?.count) {
                        console.log("Impression Count:", response.data.data.count);
                    } else {
                        console.log("No impression count available for the provided ID.");
                    }
                } catch (error) {
                    console.error("Error fetching impression count:", error);
                }
            };

            fetchImpressionCount();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch("https://web.linkoo.io:8001/api/poster/book_appointment", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ ...formData, qrcode_id: id })
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                throw new Error(`Server Error: ${errorDetails}`);
            }

            const responseData = await response.json();
            console.log("API Response:", responseData);

            toggleModal();
            navigate("/submitted", { state: { formData: responseData } });
        } catch (error) {
            console.error("Error booking appointment:", error);
            alert(`Failed to book appointment. Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
        },
        modalOverlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        modalContent: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            width: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "128px",
            maxHeight: "70vh", // Ensures it fits within the viewport
            overflowY: "auto", // Scrollable if content overflows
            display: "flex",
            flexDirection: "column", // Proper layout for child elements
        },
        modalHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
        },
        closeButton: {
            backgroundColor: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
        },
        formGroup: {
            marginBottom: "15px",
        },
        label: {
            display: "block",
            marginBottom: "5px",
            color: "black",
            textAlign: "justify",
        },
        input: {
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
        },
        submitButton: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "rgb(255, 255, 255)",
            border: "none",
            borderRadius: "28px",
            cursor: "pointer",
            width: "100%",
            height: "40px",
        },
    };
    

    return (
        <div>
            <button style={styles.button} onClick={toggleModal}>
                Book an Appointment  <img src={vector}/>
            </button>

            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={{ color: "black" }}>Book an Appointment</h2>
                            <button style={styles.closeButton} onClick={toggleModal}>
                                ×
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Full Name</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="full_name"
                                    value={formData.full_name}
                                    placeholder="Enter Your Name"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email</label>
                                <input
                                    style={styles.input}
                                    type="email"
                                    name="email_id"
                                    placeholder="Enter Email Id"
                                    value={formData.email_id}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Mobile Number</label>
                                <input
                                    style={styles.input}
                                    type="tel"
                                    name="mobile_no"
                                    placeholder="Enter Your Mobail Number"
                                    value={formData.mobile_no}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>City</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="city"
                                    placeholder="Enter City"
                                    value={formData.city}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Date</label>
                                <input
                                    style={styles.input}
                                    type="date"
                                    name="date"
                                    placeholder="Enter Date"
                                    onChange={(e) => handleDateTimeChange("date", e.target.value)}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Time</label>
                                <input
                                    style={styles.input}
                                    type="time"
                                    name="time"
                                    placeholder="Enter Time"
                                    onChange={(e) => handleDateTimeChange("time", e.target.value)}
                                    required
                                />
                            </div>
                            <button
                                style={styles.submitButton}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppointmentModal;
