import React, { useEffect, useState } from "react";

const DesignPreview = ({ poster, BASE_URL, list  }) => {
    console.log("post", poster.qrdesign_id)
    const[postList, setPostList] = useState({})
    useEffect(() => {
        if(poster.qrdesign_id){
            let design = list.filter(i => poster.qrdesign_id === i.id)
            console.log("design", design)
            setPostList(design[0])
        }else {
            setPostList(poster)
        }
    }, [poster.qrdesign_id])
    if (!poster) {
        return <p>No poster selected for preview.</p>;
    }
   

    return (
        <div
            style={{
                width: "300px",
                height: "400px",
                backgroundImage: `url(${BASE_URL}${postList.poster_url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                margin: "0 auto",
            }}
        >
            <div style={{ color: "#000", textAlign: "center", padding: "26px" }}>
                <h2>Scan<br />QR Code</h2>
                <p>{postList.header_content }</p>
            </div>
            <div style={{
                color: "#000", textAlign: "center",
                margin: "55px 0px"
            }}>
                <p>{postList.description }</p>
            </div>
        </div>
    );
};

export default DesignPreview;
