import React, { useState, useEffect } from "react";
import { Doughnut } from "react-chartjs-2";
import { Col, Row } from "react-bootstrap";
import Tabs from "../../../Component/Merchant/Tabs";
import { DashTabs } from "../../../pages/Merchant/Dashboard/Utils/utils";
import api from "../../../Service/Axios";
import { dashboard_stats } from "../../../Service/ApiUrls";

const DoughnutDemo = () => {
  const [activeId, setActiveId] = useState(1);
  const [staticActiveQR, setStaticActiveQR] = useState(0);
  const [staticInactiveQR, setStaticInactiveQR] = useState(0);
  const [dynamicActiveQR, setDynamicActiveQR] = useState(0);
  const [dynamicInactiveQR, setDynamicInactiveQR] = useState(0);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await api.get(dashboard_stats);
        if (response.data.status) {
          const data = response.data.data;
          setStaticActiveQR(data.static_active_qr);
          setStaticInactiveQR(data.static_inactive_qr);
          setDynamicActiveQR(data.dynamic_active_qr);
          setDynamicInactiveQR(data.dynamic_inactive_qr);
        }
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();
  }, []);

  const data = {
    datasets: [
      {
        backgroundColor: ["#d235c3", "#5EB02B"],
        hoverBackgroundColor: ["#d235c3", "#5EB02B"],
        data: activeId === 1
          ? [staticActiveQR, staticInactiveQR]
          : [dynamicActiveQR, dynamicInactiveQR],
      },
    ],
  };

  const total = activeId === 1
    ? staticActiveQR + staticInactiveQR
    : dynamicActiveQR + dynamicInactiveQR;

    console.log("to",total)

  const plugins = [
    {
      beforeDraw: function (chart) {
        const width = chart.width;
        const height = chart.height;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / 200).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        const text = `Total Code: ${total}`;
        const textX = Math.round((width - ctx.measureText(text).width) / 2);
        const textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    },
  ];

  return (
    <div>
      <Row>
        <Col md={12} lg={8}>
          <Tabs values={DashTabs} activeId={activeId} setActiveId={setActiveId} />
        </Col>
      </Row>
      <Doughnut data={data} plugins={plugins}       key={total} // Adding key to force re-render when total changes
 options={{ responsive: true, cutout: "80%" }} />
    </div>
  );
};

export default DoughnutDemo;
