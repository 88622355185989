import React, { useState, useEffect } from "react";
import { Table, Modal, Button } from "react-bootstrap";
import bin from "../../../../assets/image/bin.svg";
import edit from "../../../../assets/image/edit.svg";
import CreateLocation from "./CreateLocation"; // Import CreateLocation component
import { locations, location_delete } from "../../../../Service/ApiUrls";
import api from "../../../../Service/Axios";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import Button from "@mui/material/Button";

function LocationTable() {
  const [locationList, setLocationList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editLocation, setEditLocation] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Fetch locations
  const fetchLocations = async () => {
    try {
      const response = await api.get(locations);
      if (response.data.status) {
        setLocationList(response.data.data.data);
      } else {
        console.error("Failed to fetch location list");
      }
    } catch (error) {
      console.error("Error while fetching locations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  // Handle edit location
  const handleEdit = (location) => {
    setEditLocation(location);
    setShowModal(true);
  };

  // Handle delete location
  const handleDelete = async () => {
    try {
      const response = await api.get(`${location_delete}/${deleteId}`);
      if (response.data.status) {
        setLocationList((prevList) => prevList.filter((loc) => loc.id !== deleteId));
        toast.success(response.data.message || "Location deleted successfully");
      } else {
        toast.error(response.data.message || "Failed to delete location");
      }
    } catch (error) {
      console.error("Error deleting location:", error);
      toast.error("An error occurred while deleting the location.");
    } finally {
      setShowDeleteDialog(false);
      setDeleteId(null);
    }
  };

  const confirmDelete = (id) => {
    setDeleteId(id);
    setShowDeleteDialog(true);
  };

  // Update location list after create/edit
  const updateLocationList = (updatedLocation) => {
    if (editLocation) {
      setLocationList((prevList) =>
        prevList.map((loc) => (loc.id === updatedLocation.id ? updatedLocation : loc))
      );
    } else {
      setLocationList((prevList) => [...prevList, updatedLocation]);
    }
    setEditLocation(null);
  };

  return (
    <div className="custom-table mt-3">
      <Table className="location-table">
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>S.No</th>
            <th>Location</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="4" className="text-center">Loading...</td>
            </tr>
          ) : locationList.length > 0 ? (
            locationList.map((location, index) => (
              <tr key={location.id}>
                <td className="f-16 fw-500 tableText">{index + 1}</td>
                <td className="f-16 fw-500 tableText">{location.location_name}</td>
                <td className="f-16 fw-400 tableText">
                  <small
                    className={
                      location.active_status === 1 ? "status-active" : "status-inactive"
                    }
                  >
                    {location.active_status === 1 ? "Active" : "Inactive"}
                  </small>
                </td>
                <td>
                  <img
                    src={edit}
                    alt="Edit"
                    className="mt-3 me-3 cursor"
                    title="Edit"
                    onClick={() => handleEdit(location)}
                  />
                  <img
                    src={bin}
                    alt="Delete"
                    className="mt-3 cursor"
                    title="Delete"
                    onClick={() => confirmDelete(location.id)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No Locations Found</td>
            </tr>
          )}
        </tbody>
      </Table>
      <CreateLocation
        key={editLocation?.id || "new"}
        show={showModal}
        setShow={setShowModal}
        location={editLocation}
        onUpdate={updateLocationList}
      />

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this role?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="secondary" style={{border: "none",background: "none",color: "#2559ac"}}>
            Cancel
          </Button>
          <Button onClick={handleDelete} style={{ background: "#d435c5", border: "none", color: "#fff" }} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Modal show={showDeleteDialog} onHide={() => setShowDeleteDialog(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this location?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteDialog(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </div>
  );
}

export default LocationTable;
