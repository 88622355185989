import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Table from "./EventsTable";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import { Button } from "@mui/material";
import api from "../../../../Service/Axios";
import { events, export_events } from "../../../../Service/ApiUrls";

function Events() {
  const [searchTerm, setSearchTerm] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [codeNames, setCodeNames] = useState([]);
  const [codeTypes, setCodeTypes] = useState([]);
  const [selectedCodeName, setSelectedCodeName] = useState("");
  const [selectedCodeType, setSelectedCodeType] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await api.post(events);
        const eventData = Array.isArray(response.data?.data?.event_bookings)
          ? response.data.data.event_bookings
          : [];
        setEventsData(eventData);
        setFilteredEvents(eventData);

        const codeNameOptions = [
          { label: "All", value: "" },
          ...Array.from(
            new Set(eventData.map((event) => event.qrcode_name))
          ).map((name) => ({ label: name, value: name })),
        ];
        const codeTypeOptions = [
          { label: "All", value: "" },
          ...Array.from(
            new Set(eventData.map((event) => event.qrcode_type))
          ).map((type) => ({ label: type, value: type })),
        ];
        setCodeNames(codeNameOptions);
        setCodeTypes(codeTypeOptions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    applyFilters(value, selectedCodeName, selectedCodeType);
  };

  const handleCodeNameChange = (event) => {
    const value = event.target.value;
    setSelectedCodeName(value);
    applyFilters(searchTerm, value, selectedCodeType);
  };

  const handleCodeTypeChange = (event) => {
    const value = event.target.value;
    setSelectedCodeType(value);
    applyFilters(searchTerm, selectedCodeName, value);
  };

  const applyFilters = (search, codeName, codeType) => {
    const filtered = eventsData.filter(
      (event) =>
        (event.full_name?.toLowerCase().includes(search) ||
          event.email_id?.toLowerCase().includes(search)) &&
        (codeName === "" || event.qrcode_name === codeName) &&
        (codeType === "" || event.qrcode_type === codeType)
    );
    setFilteredEvents(filtered);
  };

  // const handleExport = async () => {
  //   try {
  //     const response = await api.post(export_events, {}, { responseType: "blob" });
  //     const blob = new Blob([response.data], { type: response.headers["content-type"] });
  //     const downloadUrl = URL.createObjectURL(blob);

  //     // Create a link to download the file
  //     const link = document.createElement("a");
  //     link.href = downloadUrl;
  //     link.download = "exported_events.xlsx"; // Set the file name
  //     link.click();

  //     // Clean up the URL object
  //     URL.revokeObjectURL(downloadUrl);
  //   } catch (error) {
  //     console.error("Error exporting events:", error);
  //   }
  // };


  const handleExport = async () => {
    try {
      const response = await api.post(export_events, {}, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = URL.createObjectURL(blob);
  
      // Create a link to download the file
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "exported_events.csv"; // Set the file name
      link.click();
  
      // Clean up the URL object
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error exporting events:", error);
    }
  };
  
  return (
    <React.Fragment>
      <div>
        <Row>
          <Col md={6} lg={4}>
            <SearchBox
              key_name="searchTerm"
              value={searchTerm}
              onhandlechange={handleSearchChange}
            />
          </Col>
          <Col md={12} lg={8}>
            <Dropdown
              list={codeNames}
              value={selectedCodeName}
              onhandlechange={handleCodeNameChange}
            />
            <Dropdown
              list={codeTypes}
              value={selectedCodeType}
              onhandlechange={handleCodeTypeChange}
            />
            <Button
              variant="contained"
              className="btn"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                borderRadius: "50px",
                float: "right",
              }}
              onClick={handleExport} // Add export functionality
            >
              <b>Export</b>
            </Button>
          </Col>
        </Row>
      </div>
      <Table eventsData={filteredEvents} loading={loading} />
    </React.Fragment>
  );
}

export default Events;
