import axios from "axios";
import { baseUrl, baseLandingUrl } from "../Config";
import { toast } from "react-toastify";

const api = axios.create({
    baseURL: baseUrl,
  });

 
// Add a request interceptor
//request
api.interceptors.request.use(
function (config) {
// console.log(config);
 
config.headers = {
"Content-Type": "application/json",
Authorization: `Bearer ${localStorage.getItem("token")}`,
};
 
return config;
},
function (error) {
// Do something with request error
 
return Promise.reject(error);
}
);
//response
// Add a response interceptor
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // Handle token expiration and redirect to login
    if (
      error.response &&
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      toast.error("Session expired. Redirecting to login.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      localStorage.removeItem("token"); // Clear token
      window.location.href = "/login"; // Redirect to login
    }
    return Promise.reject(error);
  }
);
// api.interceptors.response.use(
//     function (response) {
//       // Any status code that lie within the range of 2xx cause this function to trigger
//       // Do something with response data
//       return response;
//     },
//     function (error) {
//       console.error("An error occurred", error);
//       // Example: Redirect on unauthorized
//       if (
//         error.response &&
//         error.response.status === 401 &&
//         window.location.pathname !== "/login"
//       ) {
//         // logout();
//       }
   
//       return Promise.reject(error);
//     }
//   );
export default api;