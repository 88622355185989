import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import RadioBtn from "../../../../Component/Merchant/RadioBtn";
import {
  check_draft_status,
  locations,
  qr_code_detail,
} from "../../../../Service/ApiUrls";
import api from "../../../../Service/Axios";
import { toast } from "react-toastify";

function DetailsQr(props) {
  const { setActiveId, formData, handleInputChange, setQrId, id, onSubmit, isDisabled, qrCodeData, isFormDisabled, isVideoUrlDisabled } =
    props;
  const [videoFile, setVideoFile] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("file");
  const [locationsList, setLocations] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loading state for the "Next" button
  const [errors, setErrors] = useState({}); // State for validation errors
  const [customParameters, setCustomParameters] = useState([]);



  // Fetch locations
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await api.get(locations);
        console.log("loc", response)
        const locationData = response.data?.data?.data || [];
        setLocations(locationData);
      } catch (error) {
        toast.error("Failed to fetch locations. Please try again.");
        console.error("Error fetching locations:", error);
      }
    };

    fetchLocations();
  }, []);

  const handleInputsChange = (e, index) => {
    const { name, value } = e.target;
    const updatedParameters = [...customParameters];
    updatedParameters[index] = {
      ...updatedParameters[index],
      [name]: value,
    };
    setCustomParameters(updatedParameters);
  };

  // Add a new custom parameter row
  const handleAddParameter = () => {
    setCustomParameters([...customParameters, { language: "", section: "" }]);
  };

  // Remove a custom parameter row
  const handleDeleteParameter = (index) => {
    const updatedParameters = customParameters.filter((_, i) => i !== index);
    setCustomParameters(updatedParameters);
  };
  const handleNext = async () => {
    console.log("data", formData)
    if (!validateForm()) {
      return; // Stop if validation fails
    }
    setIsLoading(true);
    try {
      await onSubmit();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      console.error("Error during form submission:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleLocationOptionChange = (option) => {
    setSelectedOption(option);
    // Clear the other input's value
    if (option === "dropdown") {
      handleInputChange({ target: { name: "lat_long", value: "" } });
    } else if (option === "lat_long") {
      handleInputChange({ target: { name: "location_id", value: "" } });
    }
  };


  const validateField = (field, value, errorMessage) => {
    if (!value) {
      return errorMessage;
    }
    return null;
  };

  const validateForm = () => {
    const newErrors = {
      qrcode_name: validateField(
        "qrcode_name",
        formData.qrcode_name,
        "QR Code Name is required."
      ),
      qrcode_type: validateField(
        "qrcode_type",
        formData.qrcode_type,
        "QR Code Type is required."
      ),
      video_mode: validateField(
        "video_mode",
        formData.video_mode,
        "QR Code video mode is required."
      ),

      ...(selectedOption === "dropdown" && {
        location_id: validateField(
          "location_id",
          formData.location_id,
          "Location is required."
        ),
      }),
      ...(selectedOption === "lat_long" && {
        lat_long: validateField(
          "lat_long",
          formData.lat_long,
          "Latitude and Longitude are required."
        ),
      }),

      video_url: validateField(
        "video_url",
        formData.video_url,
        "QR Code video Url is required."
      ),

    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(Boolean); // Returns true if no errors
  };

  // Function to handle input change for custom parameters
  const handleCustomParameterChange = (index, field, value) => {
    const updatedParameters = [...customParameters];
    updatedParameters[index][field] = value;
    setCustomParameters(updatedParameters);
  };

  // Function to add a new custom parameter
  const addCustomParameter = () => {
    setCustomParameters([...customParameters, { language: "", importantSection: "" }]);
  };

  // Function to remove a custom parameter
  const removeCustomParameter = (index) => {
    const updatedParameters = customParameters.filter((_, i) => i !== index);
    setCustomParameters(updatedParameters);
  };


  return (
    <div
      className="form-container"
      style={{
        maxHeight: "500px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        padding: "15px",
      }}
    >

      <style>
        {`
          .form-container::-webkit-scrollbar {
            display: none;
          }
          .box-main {
            width: 469px;
            height: 114px;
          }
          .box {
            padding: 10px;
          }
          .input-box {
            padding: 10px;
            margin: 0 18px;
          }
          .input {
            width: 404px;
            height: 35px;
            border: 1px solid #00000047;
            border-radius: 4px;
          }
          .drag-drop-box {
            border: 2px dashed #ccc;
            padding: 20px;
            border-radius: 8px;
            text-align: center;
            background-color: #f9f9f9;
          }
          .file-info {
            margin-top: 8px;
            color: #666;
            font-size: 14px;
          }

          .drag-drop-box {
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
  position: relative;
}

.drag-text {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn-upload {
  margin-top: 10px;
  background-color: #d63384;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-upload:hover {
  background-color: #b0276b;
}

.btn-add-url {
  margin-top: 10px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-add-url:disabled {
  background-color: #d6d6d6;
  cursor: not-allowed;
}

.file-info {
  margin-top: 8px;
  color: #666;
  font-size: 14px;
}
  .radio{
      display: flex;
  }

        `}
      </style>


      <Row>
        <Col md={6}>
          <InputBox
            type="text"
            label="QR Code Name"
            placeholder="Test Code"
            key_name="qrcode_name"
            {...formData}
            onChange={handleInputChange}
            disabled={isFormDisabled}

          />
          {errors.qrcode_name && <span className="error">{errors.qrcode_name}</span>}

        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row>

      {/* </Col>
        
      </Row> */}
      <br />

      <Row className="d-inline-flex">
        <h3 className="Inter-Regular f-14 fw-400">Select QR Code Type</h3>
        <Col>
          <div style={{ display: 'flex', gap: '10px' }}>
            <label>
              <input
                type="radio"
                value="static"
                name="qrcode_type"
                onChange={handleInputChange}
                style={{ margin: "0 10px" }}
                checked={formData.qrcode_type === "static"}
                disabled={isFormDisabled}

              />
              Static QR
            </label>
            <label>
              <input
                type="radio"
                value="dynamic"
                name="qrcode_type"
                onChange={handleInputChange}
                style={{ margin: "0 10px" }}
                checked={formData.qrcode_type === "dynamic"}
                disabled={isFormDisabled}

              />
              Dynamic QR
            </label>
          </div>
          {errors.qrcode_type && <span className="error">{errors.qrcode_type}</span>}

        </Col>
      </Row>
      <br />
      <Row className="d-flex justify-content-between align-items-center border-bottom py-3">
        <h3 className="Inter-Regular f-14 fw-400">
          We recommend creating a dynamic QR code if you want to track
          performance and edit data even after printing.
        </h3>
      </Row>
      <Row className="d-inline-flex" style={{ marginTop: "23px" }}>
        <h3 className="Inter-Regular f-14 fw-400">Select Video Mode</h3>

        <Col>
          <div style={{ display: 'flex', gap: '10px' }}>
            <label>
              <input
                type="radio"
                value="portrait"
                name="video_mode"
                onChange={handleInputChange}
                style={{ margin: "0 10px" }}
                checked={formData.video_mode === "portrait"}
                disabled={isFormDisabled}

              />
              Portrait
            </label>
            {/* <label>
              <input
                type="radio"
                value="landscape"
                name="video_mode"
                onChange={handleInputChange}
                style={{ margin: "0 10px" }}
              />
              Landscape
            </label> */}
          </div>
          {errors.video_mode && <span className="error">{errors.video_mode}</span>}

        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row>

      {/* Custom Parameters */}
      {/* <Row className="d-inline-flex" style={{ marginTop: "23px" }}>
        <h3 className="Inter-Regular f-14 fw-400">
          Add your Customized Parameter
        </h3>
        {customParameters.map((param, index) => (
        <Row
          className="d-inline-flex"
          key={index}
          style={{ marginTop: "10px" }}
        >
          <Col style={{ width: "160px" }}>
            <input
              type="text"
              placeholder="Language"
              className="t-box input-t"
              name="language"
              value={param.language}
              onChange={(e) => handleInputsChange(e, index)}
            />
          </Col>
          <Col style={{ width: "160px" }}>
            <input
              type="text"
              placeholder="Important Section"
              className="t-box input-t"
              name="section"
              value={param.section}
              onChange={(e) => handleInputChange(e, index)}
            />
          </Col>
          <Col>
            <button
              className="btn-export bgcolor-white custom"
              onClick={() => handleDeleteParameter(index)}
            >
              <span className="custom-span">Delete</span>
            </button>
          </Col>
        </Row>
      ))}

      <Row style={{ marginTop: "10px" }}>
        <Col>
          <button
            className="btn-export bgcolor-white custom"
            onClick={handleAddParameter}
          >
            <span className="custom-span">Add Custom Parameter</span>
          </button>
        </Col>
      </Row>

      </Row> */}

      {/* <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row> */}

      {/* Location Details */}
      <Row className="d-inline-flex mt-4">
        <h3 className="Inter-Regular f-14 fw-400">Add Location Details</h3>
        <div className="box-container">
          <Row>
            <div className="box-container">
              <Row>
                {/* Select Location Name */}
                <Col md={6} className="box-main">
                  <div className="box">
                    <label>
                      <input
                        type="radio"
                        name="locationOption"
                        value="dropdown"
                        checked={selectedOption === "dropdown"}
                        onChange={() => handleLocationOptionChange("dropdown")}
                        style={{ margin: "0 10px" }}
                        disabled={isFormDisabled}
                        {...formData}

                      />

                      Select Location Name
                    </label>
                    <select
                      className="form-select mt-2"
                      value={formData.location_id}
                      onChange={handleInputChange}
                      name="location_id"
                      disabled={selectedOption !== "dropdown" && isFormDisabled}
                      {...formData}


                    >
                      <option value="">Select Location</option>
                      {locationsList.map((location, index) => (
                        <option key={index} value={location.id}>
                          {location.location_name}
                        </option>
                      ))}
                    </select>
                    {selectedOption === "dropdown" && errors.location_id && (
                      <span className="error">{errors.location_id}</span>
                    )}
                  </div>
                </Col>

                {/* Add Latitude & Longitude */}
                <Col md={6} className="box-main">
                  <div className="box">
                    <label>
                      <input
                        type="radio"
                        name="locationOption"
                        value="lat_long"
                        checked={selectedOption === "lat_long"}
                        onChange={() => handleLocationOptionChange("lat_long")}
                        style={{ marginRight: "10px" }}
                        disabled={isFormDisabled}


                      />
                      Latitude & Longitude Parameters
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Latitude & Longitude (e.g., 12.34 & 56.78)"
                      className="input-field"
                      value={formData.lat_long}
                      onChange={handleInputChange}
                      name="lat_long"
                      key_name="lat_long"
                      {...formData}

                      disabled={selectedOption !== "lat_long"}
                    />
                    {selectedOption === "lat_long" && errors.lat_long && (
                      <span className="error">{errors.lat_long}</span>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Row>
        </div>
      </Row>
      <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row>

      {/* Video Upload */}
      <Row className="mt-4">
        <h3 className="Inter-Regular f-14 fw-400">Upload the video file</h3>
        <Col md={6}>
          <div className="drag-drop-box">
            <p className="drag-text">Drag and Drop your files here</p>
            <input
              type="file"
              accept="video/*"
              className="file-input"
              onChange={handleInputChange}
              name="video_url"

              disabled={isVideoUrlDisabled} // Disable if static

            // disabled={selectedOption !== "file"}
            />
            <p className="file-info">Maximum size: 25MB</p>
            {formData.video_url && !formData.video_url?.startsWith("http") &&
              <p className="text-center">Selected File Name : {formData.video_url}</p>}
            <div className="d-flex justify-content-center" disabled={isVideoUrlDisabled}>
              <button className="btn-upload" 
              >Browse</button>

            </div>

            {videoFile && (
              <p className="file-info">
                Selected File: {videoFile.name} (
                {(videoFile.size / 1024 / 1024).toFixed(2)} MB)
              </p>
            )}

          </div>
        </Col>

        <Col md={6}>
          <div>
            <p>If you prefer, you can also enter the URL of the video</p>
            <input
              type="text"
              placeholder="Enter Video URL"
              className="input mt-2"
              value={formData.video_url?.startsWith("http") ? formData.video_url : ""}
              key_name="video_url"
              name="video_url"
              onChange={(e) => handleInputChange(e)}
              style={{ width: "100%" }}
              disabled={isVideoUrlDisabled} // Disable if static

            />
            {/* {errors.video_url && <span className="error">{errors.video_url}</span>} */}
          </div>
        </Col>
        {errors.video_url && (
          <span className="error">{errors.video_url}</span>
        )}
      </Row>

      <br />
      <div className="d-flex justify-content-start">
        <div className="d-flex justify-content-start">
          <button
            className="btn-download"
            onClick={handleNext}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Next"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DetailsQr;
