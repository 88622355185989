import React, { useState, useEffect } from "react";
import apiLanding from "../../../../Service/AxiosLanding";
import { useParams } from 'react-router-dom';
import { register_event } from "../../../../Service/ApiUrls";
import axios from "axios";
import vector from "../../../../assets/image/template/Vector.png"


const EventsLanding = ({id}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        full_name: "",
        email_id: "",
        mobile_no: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [message, setMessage] = useState("");

    const toggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    useEffect(() => {
        if (id) {
            // Fetch impression count when the component mounts
            const fetchImpressionCount = async () => {
                try {
                    const response = await axios.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                    if (response?.data?.data?.count) {
                        console.log("Impression Count:", response.data.data.count);
                    } else {
                        console.log("No impression count available for the provided ID.");
                    }
                } catch (error) {
                    console.error("Error fetching impression count:", error);
                }
            };

            fetchImpressionCount();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setMessage("");
    
        // Prepare the data to send in the POST request
        const dataToSend = {
            qrcode_id: id, // Send `qrcode_id` as part of the body
            ...formData,   // Include other form data (name, email, mobile, etc.)
        };
    
        try {
            // Send the data to the backend
            const response = await fetch("https://web.linkoo.io:8001/api/poster/register_event", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...formData, qrcode_id: id}),
            });
    
            if (response.ok) {
                const result = await response.json();
                setMessage(result.data.end_screen_msg || "Registration successful! Thank you for registering.");
            } else {
                setMessage("Something went wrong. Please try again.");
            }
        } catch (error) {
            console.error("Error during registration:", error);
            setMessage("An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };
    

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
        },
        modalOverlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        modalContent: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            width: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "128px",
            maxHeight: "70vh", // Ensures it fits within the viewport
            overflowY: "auto", // Scrollable if content overflows
            display: "flex",
            flexDirection: "column", // Proper layout for child elements

        },
        modalHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
        },
        closeButton: {
            backgroundColor: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
        },
        formGroup: {
            marginBottom: "15px",
        },
        label: {
            display: "block",
            marginBottom: "5px",
            // fontWeight: "bold",
            color: "black",
            textAlign: "left",
        },
        input: {
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
        },
        submitButton: {
            padding: "10px 20px",
            height: "39px",
            backgroundColor: "rgb(220 55 204)",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
            width: "100%",
        },
        message: {
            marginTop: "20px",
            color: "#4CAF50",
            fontSize: "16px",
        },
        agenda: {
            marginTop: "20px",
            color: "black",
            textAlign: "left",
        },
        agendaList: {
            marginLeft: "-30px",
            color: "black",
            textAlign: "left",
            listStyle: "none",
        },
    };

    return (
        <div>
            <button style={styles.button} onClick={toggleModal}>
                Event <img src={vector}/>
            </button>

            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={{ color: "black" }}>Event Registration</h2>
                            <button style={styles.closeButton} onClick={toggleModal}>
                                ×
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Full Name</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="full_name"
                                    value={formData.full_name}
                                    placeholder="Enter Your Name"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email ID</label>
                                <input
                                    style={styles.input}
                                    type="email"
                                    name="email_id"
                                    placeholder="Enter Your Email Id"
                                    value={formData.email_id}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Mobile Number</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="mobile_no"
                                    placeholder="Eneter Mobile Number"
                                    value={formData.mobile_no}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.agenda}>
                                <h5>Meeting Agenda</h5>
                                <ul style={styles.agendaList}>
                                    <li>09:00 AM - Registration</li>
                                    <li>10:00 AM - Introduction</li>
                                    <li>10:30 AM - Welcome Speech</li>
                                    <li>11:00 AM - Guest Lecture Speech</li>
                                    <li>10:30 AM - Welcome Speech</li>
                                    <li>11:00 AM - Guest Lecture Speech</li>
                                </ul>
                            </div>
                            <button style={styles.submitButton} type="submit" disabled={isSubmitting}>
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>
                        </form>
                        {message && <p style={styles.message}>{message}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default EventsLanding;
