import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify"; // Import Toastify
import api from "../../../../Service/Axios";
import { active_roles, user_create, update_user } from "../../../../Service/ApiUrls";

const CreateUser = (props) => {
  const { show, setShow, editData } = props;
  const [status, setStatus] = useState([
    { label: "Active", value: 0 },
    { label: "Inactive", value: 1 },
  ]);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await api.get(active_roles);
        const rolesData = response.data?.data?.roles || [];
        const formattedRoles = rolesData.map((role) => ({
          label: role.role_name,
          value: role.id,
        }));
        setRoles(formattedRoles);
      } catch (error) {
        toast.error("Error fetching roles!");
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();

    if (editData) {
      setUserName(editData.full_name || "");
      setEmail(editData.email_id || "");
      setSelectedRole(editData.role_id || null);
      setSelectedStatus(editData.active_status || null);
    }
  }, [editData]);

  const handleClose = () => setShow(false);

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleCreateUser = async () => {
    if (!userName || !email || !selectedRole) {
      toast.warn("Please fill out all fields.");
      return;
    }

    const params = {
      email_id: email,
      full_name: userName,
      role_id: selectedRole,
      active_status: selectedStatus,
    };

    try {
      const response = editData
        ? await api.put(`${update_user}/${editData.id}`, params) // Use PUT for update
        : await api.post(user_create, params); // Use POST for create

      if (response.data?.status) {
        toast.success(editData ? "User updated successfully!" : "User created successfully!");
        setShow(false); // Close the modal
      } else {
        toast.error(response.data?.message || "Failed to process user.");
      }
    } catch (error) {
      console.error("Error processing user:", error);
      toast.error("An error occurred while processing the user.");
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{editData ? "Edit User" : "Add New User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>User Name</label>
          <br />
          <input
            type="text"
            placeholder="User Name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            className="form-control mt-2"
          />
          <label className="mt-2">Email Id</label>
          <br />
          <input
            type="text"
            placeholder="Email Id"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control mt-2"
          />
          <label className="mt-2">Role</label>
          <br />
          <select
            value={selectedRole}
            onChange={handleRoleChange}
            className="form-control mt-2"
          >
            <option value="">Select Role</option>
            {roles.map((role) => (
              <option key={role.value} value={role.value}>
                {role.label}
              </option>
            ))}
          </select>
          <label className="mt-2">Status</label>
          <br />
          <select
            value={selectedStatus}
            onChange={handleStatusChange}
            className="form-control mt-2"
          >
            <option value="">Select Status</option>
            {status.map((statusOption) => (
              <option key={statusOption.value} value={statusOption.value}>
                {statusOption.label}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn-bg-color" onClick={handleCreateUser}>
            {editData ? "Update" : "Create"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateUser;
