import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";

const Feedback = ({ handleInputChange, formData }) => {
  const [feedbackData, setFeedbackData] = useState({
    cta_name: "",
    end_screen_message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFeedbackData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formData?.feedbacks?.length > 0) {
      const feedback = formData.feedbacks[0]; // Use the first feedback
      setFeedbackData({
        cta_name: feedback.cta_name || "",
        end_screen_message: feedback.end_screen_message || "",
      });
    }
  }, []);

  useEffect(() => {
    const { cta_name, end_screen_message } = feedbackData;
    if (cta_name && end_screen_message) {
      handleInputChange({
        target: {
          name: "feedbacks",
          value: [feedbackData],
        },
      });
    }
  }, [feedbackData]);

  return (
    <div className="border-container" style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}>
      <Row className="mb-3">
        <Form.Label htmlFor="ctaName">CTA Name</Form.Label>
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={feedbackData.cta_name}
            name="cta_name"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center border-bottom "></Row>
      <br />
      <Row>
        <Col md={6}>
          <Form.Label htmlFor="ctaName" className="form-label-h">
            End Screen Message/link
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Description"
            value={feedbackData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Feedback;
