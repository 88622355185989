import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../../../Service/Axios";
import { getPosters, qr_code_design } from "../../../../Service/ApiUrls";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import TemplateDesignQr from "./DesignPreview";
import DesignPreview from "./DesignPreview";

function Design(props) {
  const { id, setActiveId, selectedPoster, setSelectedPoster, formData, isFormDisabled, posters, isLoading, setIsLoading} = props;
  const navigate = useNavigate();
  const BASE_URL = "https://linkoo.s3.amazonaws.com/";

 

  const handleCheckboxChange = (poster) => {
    if (selectedPoster && selectedPoster.id === poster.id) {
      setSelectedPoster(null);
      // toast.info("Poster deselected.");
    } else {
      setSelectedPoster(poster);
      // toast.success("Poster selected!");
    }
  };
  

  const handleSubmit = async () => {
    if (!selectedPoster) {
      toast.warning("Please select a poster before submitting.");
      return;
    }

    const data = {
      qrdesign_id: selectedPoster.id,
      // template_id: selectedPoster.id,
    };

    try {
      setIsLoading(true);
      const response = await api.put(`${qr_code_design}${id}`, data);
      toast.success("QR code design created successfully!");
      navigate(-1);
    } catch (error) {
      toast.error("Error creating QR code design.");
      console.error("Error submitting QR code details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreate = async () => {
    setIsLoading(true); // Show loader
    try {
      await handleSubmit();
      // toast.success("QR Code details submitted successfully!");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "An error occurred. Please try again.";
      toast.error(errorMessage);
      console.error("Error during form submission:", error);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <>
      <Row>
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" variant="primary" />
            <p>Loading...</p>
          </div>
        ) : (
          posters?.map((item) => (
            <Col md={4} key={item.id} className="text-center d-flex align-items-center">
              <input
                type="checkbox"
                name="qrdesign_id"
                value={item.id}
                onChange={() => handleCheckboxChange(item)}
                {...formData} 
                checked={(selectedPoster?.qrdesign_id === item.id ) || (selectedPoster?.id === item.id)} 
                disabled={isFormDisabled}
                style={{ marginRight: "10px" }}

                // checked = {formData.video_mode === "portrait"}
                // Ensure checkbox reflects the selected state

              />
              <div
                style={{
                  position: "relative",
                  display: "inline-block",
                  width: "300px",
                  height: "400px",
                  backgroundImage: `url(${BASE_URL}${item.poster_url})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <div style={{ position: "absolute", top: "20px", width: "100%", color: "#000" }}>
                  <h2>
                    Scan<br />QR Code
                  </h2>
                  <p>{item.header_content }</p>
                </div>
                <div style={{ position: "absolute", bottom: "20px", width: "100%", color: "#000" }}>
                  <p>{item.description }</p>
                </div>
              </div>
            </Col>
          ))
        )}
      </Row>
      <br />
      <Row className="d-inline-flex">
        <Col className="d-flex justify-content-start">
          <button
            className="btn-download bgcolor-white"
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                setActiveId(2);
                setIsLoading(false);
                toast.info("Navigated back successfully!");
              }, 500);
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <h1 className="DMSans-Regular f-18 fw-600 color-blak mb-0">Back</h1>
            )}
          </button>
        </Col>
        <Col className="d-flex justify-content-between">
          <button
            className="btn-download bgcolor-cerise"
            onClick={handleCreate}
            // disabled={isLoading}
            // disabled={isFormDisabled}

          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <h1 className="DMSans-Regular f-18 fw-600 color-white mb-0">
                Create
              </h1>
            )}
          </button>
        </Col>
      </Row>
         {/* <DesignPreview poster={selectedPoster} BASE_URL="https://linkoo.s3.amazonaws.com/" /> */}
          


    </>
  );
}

export default Design;
