import { BorderStyle } from "@mui/icons-material";
import { height, textAlign } from "@mui/system";
import React, { useState, useEffect } from "react";
import axios from "axios";
import vector from "../../../../assets/image/template/Vector.png";

const ContactLanding = ({ id, contactInfo }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (id) {
      // Fetch impression count when the component mounts
      const fetchImpressionCount = async () => {
        try {
          const response = await axios.get(
            `https://web.linkoo.io:8001/api/poster/impression/${id}`
          );
          if (response?.data?.data?.count) {
            console.log("Impression Count:", response.data.data.count);
          } else {
            console.log("No impression count available for the provided ID.");
          }
        } catch (error) {
          console.error("Error fetching impression count:", error);
        }
      };

      fetchImpressionCount();
    }
  }, [id]);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  console.log(contactInfo);

  const handleButtonClick = () => {
    window.open("https://clientportal.linkoo.io/", "_blank", "noopener,noreferrer");
  };


  const styles = {
    button: {
      padding: "10px 20px",
      backgroundColor: "rgb(212 54 197)",
      color: "#fff",
      border: "none",
      borderRadius: "35px",
      cursor: "pointer",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      width: "400px",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      marginTop: "232px",
    },
    modalHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    closeButton: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: "20px",
      cursor: "pointer",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: "20px",
      BorderStyle: "none",
    },
    th: {
      textAlign: "left",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      color: "black",
      border: "1px solid #ddd",
    },
    td: {
      textAlign: "left",
      padding: "10px",
      border: "1px solid #ddd",
      color: "black",
    },
    formGroup: {
      marginBottom: "15px",
    },
    label: {
      display: "block",
      marginBottom: "5px",
      fontWeight: "bold",
      color: "black",
      textAlign: "left",
    },
    input: {
      width: "100%",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
    },
    submitButton: {
      padding: "10px 20px",
      height: "39px",
      backgroundColor: "rgb(220 55 204)",
      color: "#fff",
      border: "none",
      borderRadius: "35px",
      cursor: "pointer",
      width: "100%",
    },
    agenda: {
      marginTop: "20px",
      color: "black",
      textAlign: "left",
    },
    agendaList: {
      marginLeft: "-30px",
      color: "black",
      textAlign: "left",
      listStyle: "none",
    },
    ph: {
      color: "black",
      textAlign: "left",
    },
  };

  return (
    <div>
      <button style={styles.button} onClick={toggleModal}>
        Contact Us <img src={vector} />
      </button>

      {isModalOpen && (
        <div style={styles.modalOverlay}>
          <div style={styles.modalContent}>
            <div style={styles.modalHeader}>
              <h2 style={{ color: "black" }}>Contact Info</h2>
              <button style={styles.closeButton} onClick={toggleModal}>
                ×
              </button>
            </div>

            {contactInfo && contactInfo.length > 0 ? (
              contactInfo.map((info, index) => (
                <div key={index}>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Full Name</label>
                    <p style={styles.ph}>{info.full_name}</p>
                  </div>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Company Name</label>
                    <p style={styles.ph}>{info.company_name}</p>
                  </div>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Email Id</label>
                    <p style={styles.ph}>{info.email_id}</p>
                  </div>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Mobile Number</label>
                    <p style={styles.ph}>{info.mobile_number}</p>
                  </div>
                  <div style={styles.formGroup}>
                    <label style={styles.label}>Address</label>
                    <p style={styles.ph}>{info.address}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No contact information available.</p>
            )}

            <button style={styles.submitButton} onClick={handleButtonClick}>
              View Company URL
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactLanding;
