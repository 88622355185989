import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import api from "../../../../../Service/Axios";
import { social_media_types } from "../../../../../Service/ApiUrls";

const SocialMedia = ({ formData, handleInputChange, isFormDisabled }) => {
  const [socialLinks, setSocialLinks] = useState([]);
  const [socialMediaOptions, setSocialMediaOptions] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [customLink, setCustomLink] = useState("");
  const [id, setId] = useState("");

  const handleAddSocialLink = () => {
    if (selectedPlatform && customLink) {
      const newLinks = [
        ...socialLinks,
        { social_media_id: selectedPlatform, social_media_link: customLink },
      ];
      setSocialLinks(newLinks);
      setSelectedPlatform("");
      setCustomLink("");
      const event = {
        target: {
          name: "social_media_links",
          value: newLinks,
        },
      };
      handleInputChange(event);
    }
  };

  useEffect(() => {
    if (id) {
      let socialName = socialMediaOptions.find((a) => a.id === id)?.social_media_name;
      let event = { target: { name: "socialName", value: socialName } };
      handleInputChange(event);
    }
  }, [id]);

  const handleSocialMediaChange = (event) => {
    setSelectedPlatform(event.target.value);
    handleInputChange(event);
    setId(event.target.value);
  };

  const handleDeleteLink = (index) => {
    const updatedLinks = socialLinks.filter((_, i) => i !== index);
    setSocialLinks(updatedLinks);
    const event = {
      target: {
        name: "social_media_links",
        value: updatedLinks,
      },
    };
    handleInputChange(event);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get(social_media_types);
        setSocialMediaOptions(res?.data?.data?.social_media_types || []);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData?.social_media_links?.length > 0) {
      setSocialLinks(formData.social_media_links);
    }
    
  }, [formData?.social_media_links]);
  console.log("social links", socialLinks)
  console.log("social media options", socialMediaOptions)

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px" }}
    >
      {!isFormDisabled && (
        <Row className="mb-3">
          <Form.Label htmlFor="customLink" className="form-label-h">
            Custom Social Link
          </Form.Label>
          <Col md={4}>
            <Form.Select
              aria-label="Select Social Media Platform"
              value={selectedPlatform}
              name="social_media_name"
              onChange={handleSocialMediaChange}
            >
              <option value="" disabled>
                Select Social Links
              </option>
              {socialMediaOptions.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.social_media_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={6}>
            <Form.Control
              type="text"
              placeholder="Enter Custom Link"
              value={customLink}
              onChange={(e) => setCustomLink(e.target.value)}
              name="social_media_link"
            />
          </Col>

          <Col md={2}>
            <Button
              className="ms-2"
              variant="contained"
              onClick={handleAddSocialLink}
              style={{ backgroundColor: "#d235c3", borderRadius: "25px" }}
            >
              + Add Link
            </Button>
          </Col>
        </Row>
      )}
      {socialLinks.length > 0 && (
        <Card className="mt-4">
          <Card.Header>
            Added Social Links (
            {formData?.cta_category_id || "No Category Selected"})
          </Card.Header>
          <Card.Body>
            {socialLinks.map((link, index) => (
              <Row key={index} className="mb-2">
                <Col md={4}>
                  <span className="social-platform">
                    {
                      socialMediaOptions.find(
                        (name) => name.id == link.social_media_id
                      )?.social_media_name
                    }
                  </span>
                </Col>

                <Col>
                  <input
                    type="text"
                    className="form-control"
                    value={link.social_media_link || link.link}
                    disabled={isFormDisabled}

                    readOnly
                  />
                </Col>
              
                {!isFormDisabled && (
                <Col md={2}>
                  <Button
                    variant="outlined"
                    color="warning"
                    onClick={() => handleDeleteLink(index)}
                    disabled={isFormDisabled}
                  >
                    Delete
                  </Button>
                </Col>
                )}
              </Row>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default SocialMedia;
