import React, { useState } from 'react';
import MobileFrame from './MobileFrame';
import rectangle from "../../../../assets/image/template/Rectangle.png";
import logo from "../../../../assets/image/template/Logo.svg";
import pressurecooker from "../../../../assets/image/template/PressureCooker.svg"
import group from "../../../../assets/image/template/group.svg"
import stand from "../../../../assets/image/template/group.svg"
import { Col, Row } from "react-bootstrap";
import axios from 'axios'; // Import axios

const TemplateQr = ({
    templateId,
    headerContent,
    description,
    ctaOption,
    selectedSocialMedia,
    ctaOptions,
}) => {
    const templateImage = `https://linkoo.s3.amazonaws.com/${templateId}`;

    // State to manage form inputs
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        date: '',
        time: '',
    });

    const [ctaName, setCtaName] = useState('');
    const [loading, setLoading] = useState(false); // State to manage loading state
    const [error, setError] = useState(null); // State to manage erro
    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true
        setError(null); // Reset error state

        const payload = {
            qrcode_id: templateId, // Assuming templateId is the QR code ID
            full_name: formData.name,
            email_id: formData.email,
        };

        try {
            const response = await axios.post('https://web.linkoo.io:8001/api/poster/activate_warrenty ', payload);
            console.log('Response:', response.data);
            alert('Warranty activated successfully!'); // Show success message
        } catch (err) {
            console.error('Error activating warranty:', err);
            setError('Failed to activate warranty. Please try again.'); // Set error message
        } finally {
            setLoading(false); // Set loading to false
        }
    };
    // Handle form submission
    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     console.log('Form submitted:', formData);
    //     // You can add form submission logic here
    // };

    // Handle copy to clipboard
    const handleCopy = () => {
        const link = `https://linkoo.s3.amazonaws.com/${templateId}`;
        navigator.clipboard.writeText(link)
            .then(() => alert('Link copied to clipboard!'))
            .catch(() => alert('Failed to copy the link.'));
    };

    return (
        <MobileFrame>
            <div
                className="template-qr-container"
                style={{ backgroundImage: `url(${templateImage})` }}
            >
                {/* Header */}
                <div className="header-content">
                    <h2>{headerContent}</h2>
                </div>



                {/* Footer with Social Media */}
                {ctaOption === 1 && selectedSocialMedia && (

                    <div className="footer-content">
                        {/* Description */}
                        <div className="description">
                            <p>{description}</p>
                        </div>
                        <div className="socialMedia">
                            <button>{selectedSocialMedia}</button>
                        </div>
                        <br />
                        <div className="doc-content">
                            <div className="powered-by">
                                Powered by <img src={logo} className="company-logo" />
                            </div>
                            <div className="footer-qr">
                            </div>
                        </div>
                    </div>
                )}

                {/* Form for ctaOption === 2 */}
                {ctaOption === 2 && (
                    <div className="form-containers template-form">
                        <div className="description">
                            <p style={{ color: "#0000007a" }}>{description}</p>
                        </div>
                        
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className='input'
                                    placeholder='enter your name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className='input'
                                    placeholder='Email Id'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="mobile">Mobile Number:</label>
                                <input
                                    type="tel"
                                    id="mobile"
                                    name="mobile"
                                    className='input'
                                    placeholder='Enter your mobile number'
                                    value={formData.mobile}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="date">Date and Time:</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    className='input'
                                    value={formData.date}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <br />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                )}

                {/* Link with copy icon for ctaOption === 3 */}
                {ctaOption === 3 && (
                    <div className="link-container" style={{
                        backgroundColor: 'white',
                        padding: '62px',
                        borderRadius: '8px',
                        textAlign: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}>
                        <div className='link-div'>
                            <div className='link' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: "black", fontSize: "16px" }}>https:feed.com</span>
                                <button
                                    onClick={handleCopy}
                                    style={{
                                        background: 'none',
                                        border: 'none',
                                        cursor: 'pointer',

                                        margin: '0'
                                    }}
                                    title="Copy link to clipboard"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        style={{ width: '20px', height: '20px' }}
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M8 16v2a2 2 0 002 2h8a2 2 0 002-2V8a2 2 0 00-2-2h-2m-4 0H6a2 2 0 00-2 2v8a2 2 0 002 2h2m4-10v10m4-10v10"
                                        />
                                    </svg>
                                </button>
                                {/* <button onClick={handleCopy} style={{ padding: '5px 10px', cursor: 'pointer' }}>
                                Copy
                            </button> */}
                            </div>

                        </div>

                    </div>
                )}

                {ctaOption === 5 && (
                    <div>
                        <div className="description">
                            <p style={{ color: "white", textAlign: "center" }}>{description}</p>
                        </div>
                        <div
                            className="view-location-container"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '43px',
                                borderRadius: '8px',
                                textAlign: 'center',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >

                            <button
                                style={{
                                    padding: '10px 20px',
                                    fontSize: '16px',
                                    color: '#fff',
                                    backgroundColor: 'rgb(210 53 195)',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                }}
                                onClick={() => alert('Viewing location!')}
                            >
                                View Location
                            </button>
                        </div>
                    </div>

                )}

                {/* Event Details for ctaOption === 6 */}
                {ctaOption === 6 && (
                    <div className="event-details-container" style={{
                        backgroundColor: 'white',
                        padding: '30px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        textAlign: 'center',
                        width: '100%'
                    }}>
                        <div className="description">
                            <p style={{ color: "black" }}>{description}</p>
                        </div>
                        <h3>Event Details</h3>

                        <table style={{ width: '100%', marginBottom: '20px', color: "#000" }}>
                            <thead>
                                <tr>
                                    <th>Event Name</th>
                                    <th>Event Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Student Seminar</td>
                                    <td>Jan 20, 2024</td>
                                </tr>
                            </tbody>
                        </table>

                        <div style={{ marginBottom: '20px', color: "#000", textAlign: "justify" }}>

                            <h4> Meeting Agenda</h4>
                            <table style={{ width: '100%', marginBottom: '20px', color: "#000" }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>9:00 AM</td>
                                        <td>Registration</td>
                                    </tr>
                                    <tr>
                                        <td>10:00 AM</td>
                                        <td>Intruduction</td>
                                    </tr>
                                </tbody>
                            </table>


                        </div>

                    </div>
                )}


                {ctaOption === 7 && (
                    <div className="form-containers cta-option-7" >
                        <div className="description">
                            <p style={{ color: "black" }}>{description}</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className='input'
                                    placeholder='enter your name'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email ID:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className='input'
                                    placeholder='enter email id'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="message">Message:</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    className='input'
                                    placeholder='message'
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                    rows="4"
                                    style={{
                                        width: "290px", borderradius: "10px",
                                        border: "1px solid #c5c2c2"
                                    }}
                                ></textarea>
                            </div>
                            <br />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                )}

                {ctaOption === 8 && (
                    <div className="details-container" style={{ padding: '25px', backgroundColor: 'white', borderRadius: '8px', fontSize: '12px', width: '100%', color: 'black', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '-42px' }}>
                        <div className="description">
                            <p style={{ color: "black" }}>{description}</p>
                        </div>
                        <br />
                        <h6>Full Name</h6>
                        <p>John Doe</p>

                        <h6>Company Name</h6>
                        <p>Example Corp</p>

                        <h6>Email ID</h6>
                        <p>johndoe@example.com</p>

                        <h6>Mobile Number</h6>
                        <p>+1234567890</p>

                        <h6>Address</h6>
                        <p>123 Main Street, Cityville, Country</p>
                        <button type="submit">View Company URL</button>

                    </div>
                )}

                {ctaOption === 13 && (
                    <div className="details-container" style={{ padding: '25px', backgroundColor: 'white', borderRadius: '8px', fontSize: '12px', width: '100%', color: 'black', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '-42px' }}>
                        <div className="description">
                            <p style={{ color: "black" }}>{description}</p>
                        </div>
                        <br />
                        <img src={rectangle} className='rec-img' />
                        <br />
                        <div className="doc-content">
                            <div className="powered-by" style={{ fontSize: "21px" }}>
                                Powered by <img src={logo} className="company-logo" />
                            </div>
                            <div className="footer-qr">
                            </div>
                        </div>

                        <button type="submit">Done</button>
                        <br />

                    </div>
                )}

                {ctaOption === 14 && (
                    <div className="details-container" style={{ padding: '25px', backgroundColor: 'white', borderRadius: '8px', fontSize: '12px', width: '100%', color: 'black', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '-42px' }}>
                        <div className="product-name">
                            <h4>Pressure Cooker</h4>
                            <div className="description">
                                <p style={{ color: "black" }}>{description}</p>
                            </div>

                        </div>
                        <img src={pressurecooker} className='' />
                        <br />
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    className='input'
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="form-group">
 <label htmlFor="email">Email ID:</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    className='input'
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>} {/* Display error message */}
                            <br />
                            <button type="submit" disabled={loading}>
                                {loading ? 'Activating...' : 'Activate Warranty'}
                            </button>
                        </form>

                        <br />

                    </div>
                )}

                {ctaOption === 15 && (
                    <div className="details-container" style={{ padding: '25px', backgroundColor: 'white', borderRadius: '8px', fontSize: '12px', width: '100%', color: 'black', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginTop: '-42px' }}>
                        <div className="description">
                            <p style={{ color: "black" }}>{description}</p>
                        </div>

                        <div>
                            <Row>
                                <Col>
                                    <img src={group} className='' style={{ width: "131px" }} />
                                </Col>
                                <Col>
                                    <img src={stand} className='' style={{ width: "131px" }} />

                                </Col>
                            </Row>
                        </div>


                        <br />
                        <button type="submit">Done</button>


                        <br />

                    </div>
                )}
            </div>
        </MobileFrame >
    );
};

export default TemplateQr;
