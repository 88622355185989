import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { LiaCopy } from "react-icons/lia";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { archived_qr_code_list } from "../../../../Service/ApiUrls";
import api from "../../../../Service/Axios";
import CircularProgress from '@mui/material/CircularProgress'; // For the loader

export default function AllList({ searchTerm, codeType, ctaType, loading }) {
  const [copied, setCopied] = useState(false);
  const [qrCodes, setQrCodes] = useState([]);
  const [filteredQrCodes, setFilteredQrCodes] = useState([]);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  // Fetch the archived QR codes
  useEffect(() => {
    const fetchArchivedQrCodes = async () => {
      try {
        const response = await api.get(archived_qr_code_list);
        if (response.data.status) {
          setQrCodes(response.data.data.data);
        } else {
          console.error("Failed to fetch QR codes");
        }
      } catch (error) {
        console.error("Error fetching archived QR codes:", error);
      }
    };

    fetchArchivedQrCodes();
  }, []);

  // Filter QR codes based on searchTerm, codeType, and ctaType
  // useEffect(() => {
  //   const filterQrCodes = () => {
  //     const filtered = qrCodes.filter((qrCode) => {
  //       const matchesSearchTerm =
  //         qrCode.qrcode_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //         qrCode.qrcode_value.toLowerCase().includes(searchTerm.toLowerCase());

  //       const matchesCodeType =
  //         codeType === 0 || qrCode.qrcode_type === (codeType === 1 ? "Static" : "Dynamic");

  //       const matchesCtaType = ctaType === 0 || qrCode.cta_category_id === ctaType;

  //       return matchesSearchTerm && matchesCodeType && matchesCtaType;
  //     });

  //     setFilteredQrCodes(filtered);
  //   };

  //   if (qrCodes.length > 0) {
  //     filterQrCodes();
  //   }
  // }, [searchTerm, codeType, ctaType, qrCodes]);

  useEffect(() => {
    const filterQrCodes = () => {
      let filtered = qrCodes;

      // Search Term Filter
      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            (item.qrcode_name && item.qrcode_name.toLowerCase().includes(lowerSearchTerm)) ||
            (item.qrcode_type && item.qrcode_type.toLowerCase().includes(lowerSearchTerm)) ||
            (item.cta_category_name && item.cta_category_name.toLowerCase().includes(lowerSearchTerm))
        );
      }

      // Code Type Filter
      if (codeType !== 0) {
        const codeTypeFilter = codeType === 1 ? "Static" : "Dynamic";
        filtered = filtered.filter(
          (item) =>
            item.qrcode_type && item.qrcode_type.toLowerCase() === codeTypeFilter.toLowerCase()
        );
      }

      // CTA Type Filter
      if (ctaType !== 0) {
        filtered = filtered.filter((item) => item.cta_category_id === ctaType); // Ensure `ctaTypeId` is used for comparison
      }

      setFilteredQrCodes(filtered);
    };

    filterQrCodes();
  }, [qrCodes, searchTerm, codeType, ctaType]);


  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>Code Name</th>
            <th>Code Url</th>
            <th>Code Type</th>
            <th>Description</th>
            <th>Location</th>
            <th>CTA Type</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          <CircularProgress
                            sx={{
                              color: "pink", // Set the primary loader color to pink
                              animationDuration: "550ms", // Optional: customize the animation speed
                            }}
                            thickness={4} // Optional: make the loader thicker
                            size={50} // Optional: adjust size of the loader
                          />
                        </td>
                      </tr>
                    ) : (
            filteredQrCodes.map((qrCode) => (
              <tr key={qrCode.id}>
                <td className="f-16 fw-500 tableText">{qrCode.qrcode_name}</td>
                <td className="f-16 fw-400 tableText">
                  {qrCode.qrcode_value}{" "}
                  <CopyToClipboard text={qrCode.qrcode_value} onCopy={handleCopy}>
                    <span style={{ cursor: "pointer" }}>
                      <LiaCopy />
                      {copied && (
                        <span
                          className="tooltip"
                          style={{
                            position: "relative",
                            top: "100%",
                            left: "-5px",
                            backgroundColor: "Grey",
                            color: "black",
                            fontWeight: 500,
                            padding: "5px",
                            borderRadius: "4px",
                            fontSize: "14px",
                            opacity: 0.8,
                            width: "70px",
                          }}
                        >
                          Copied!
                        </span>
                      )}
                    </span>
                  </CopyToClipboard>
                </td>
                <td className="f-16 fw-400 tableText">{qrCode.qrcode_type}</td>
                <td className="f-16 fw-400 tableText">
                  <p style={{    textWrap: "auto",maxWidth: "280px",lineHeight: "22px"}}>{qrCode.description}</p>
                </td>
                <td className="f-16 fw-400 tableText">{qrCode.location_type}</td>
                <td className="f-16 fw-400 tableText">{qrCode.cta_category_name}</td>
              </tr>
            ))
          
          )}
        </tbody>
      </Table>
    </div>
  );
}
