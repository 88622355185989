import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import vector from "../../../../assets/image/template/Vector.png";

const DocumentDownload = ({ id, setSwaps }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [documentUrl, setDocumentUrl] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            // Fetch document URL when the component mounts
            const fetchDocumentUrl = async () => {
                try {
                    const response = await axios.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                    if (response?.data?.url) {
                        setDocumentUrl(response.data.url);
                    } else {
                        console.log("No document available for the provided ID.");
                    }
                } catch (error) {
                    console.error("Error fetching document:", error);
                }
            };

            fetchDocumentUrl();
        }
    }, [id]);

    const handleDownload = () => {
        if (documentUrl) {
            window.open(documentUrl, "_blank");
        } else {
            alert("Document is not available.");
        }
    };

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
            // display: "flex",
            alignItems: "center",
        },
        vectorIcon: {
            marginLeft: "10px",
        },
    };

    return (
        <div>
            <button style={styles.button} onClick={() => setSwaps(true)}>
                View Documents <img src={vector} alt="Download Icon" style={styles.vectorIcon} />
            </button>

            {/* {isModalOpen && (
                <div>
                    <button  style={styles.button}>Download Now</button>
                </div>
            )} */}
        </div>
    );
};

export default DocumentDownload;
