import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const Appointment = ({ formData, handleInputChange }) => {
  const [appointmentData, setAppointmentData] = useState({
    cta_name: "",
    end_screen_message: "",
  });

  useEffect(() => {
    if (formData?.appointments?.length > 0) {
      // Set the initial state from formData if it exists
      setAppointmentData({
        cta_name: formData.appointments[0]?.cta_name || "",
        end_screen_message: formData.appointments[0]?.end_screen_message || "",
      });
    }
  }, []);

  useEffect(() => {
    const { cta_name, end_screen_message } = appointmentData;
    if (cta_name && end_screen_message) {
      // Trigger parent function to handle the data
      const event = {
        target: {
          name: "appointments",
          value: [appointmentData],
        },
      };
      handleInputChange(event);
    }
  }, [appointmentData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Row className="mb-3">
        <Form.Label htmlFor="ctaName">CTA Name</Form.Label>
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={appointmentData.cta_name}
            name="cta_name"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row className="d-flex justify-content-between align-items-center border-bottom "></Row>
      <br />

      <Row>
        <Form.Label htmlFor="endScreenMessage">End Screen Message/Link</Form.Label>
        <Col md={6}>
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Description"
            value={appointmentData.end_screen_message}
            name="end_screen_message"
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Appointment;
