import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const Event = ({ handleInputChange, formData }) => {
  const [eventData, setEventData] = useState({
    event_name: "",
    event_date: "",
    event_agentas: [{ event_time: "", event_description: "" }],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAgentChange = (index, field, value) => {
    setEventData((prev) => {
      const updatedAgentas = [...prev.event_agentas];
      updatedAgentas[index][field] = value;
      return {
        ...prev,
        event_agentas: updatedAgentas,
      };
    });
  };

  const addAgent = () => {
    setEventData((prev) => ({
      ...prev,
      event_agentas: [
        ...prev.event_agentas,
        { event_time: "", event_description: "" },
      ],
    }));
  };
  console.log("event data", eventData)

  const removeAgent = (index) => {
    setEventData((prev) => ({
      ...prev,
      event_agentas: prev.event_agentas.filter((_, i) => i !== index),
    }));
  };

  useEffect(() => {
    if (formData?.event) {
      const event = formData.event;

      setEventData(formData.event);
    }
  }, []);

  useEffect(() => {
    const { event_name, event_date } = eventData;
    if (event_name && event_date) {
      handleInputChange({
        target: {
          name: "event",
          value: [eventData],
        },
      });
    }
  }, [eventData]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Form.Label className="form-label-h">Event Name</Form.Label>

      {/* Event Name */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter Event Name"
            value={eventData.event_name}
            name="event_name"
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        {/* Event Date */}
        <Form.Label>Event Date</Form.Label>

        <Col md={6}>
          <Form.Control
            type="date"
            placeholder="Enter Event Date"
            value={eventData.event_date}
            name="event_date"
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Event Time and Description */}
      <Row className="mb-3">
        <Form.Label>Agenda Details</Form.Label>

        {eventData.event_agentas?.map((agent, index) => (
          <div key={index} className="mb-3">
            <Row className="align-items-center">
              {/* Event Time */}
              <Col md={4} className="mb-3">
                <Form.Label>Time</Form.Label>

                <Form.Control
                  type="time"
                  value={agent.event_time}
                  name="event_time"
                  onChange={(e) =>
                    handleAgentChange(index, "event_time", e.target.value)
                  }
                />
              </Col>

              {/* Event Description */}
              <Col md={4} className="mb-3">
                <Form.Label>Description about Event</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter Event Description"
                  value={agent.event_description}
                  name="event_description"
                  onChange={(e) =>
                    handleAgentChange(index, "event_description", e.target.value)
                  }
                />
              </Col>

              {/* Remove Button */}
              <Col md={2}>
                {eventData.event_agentas.length > 1 && (
                  <Button
                    variant="danger"
                    onClick={() => removeAgent(index)}
                    size="sm"
                  >
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        ))}
        <Col>
          <Button variant="primary" onClick={addAgent} size="sm" className="event">
            + Add More
          </Button>
        </Col>
      </Row>

      {/* CTA Name and End Screen Message */}
      <Row className="mb-3">
        <Form.Label>CTA Name</Form.Label>

        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            value={formData.cta_name}
            name="cta_name"
            onChange={handleInputChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <Form.Label>End Screen Message/link</Form.Label>

          <Form.Control
            as="textarea"
            rows={2}
            type="text"
            placeholder="Enter End Screen Message"
            value={formData.end_screen_message}
            name="end_screen_message"
            onChange={handleInputChange}
          />
        </Col>
      </Row>

      {/* Display Added Event Data */}
      {eventData.event_name && eventData.event_date && (
        <Card className="mt-4">
          <Card.Header>Added Event Details</Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <strong>Event Name:</strong> {eventData.event_name}
              </Col>
              <Col md={6}>
                <strong>Event Date:</strong> {eventData.event_date}
              </Col>
            </Row>
            {eventData.event_agentas?.map((agent, index) => (
              <Row key={index}>
                <Col md={4}>
                  <strong>Event Time:</strong> {agent.event_time}
                </Col>
                <Col md={4}>
                  <strong>Description:</strong> {agent.event_description}
                </Col>
              </Row>
            ))}
            <Row>
              <Col md={6}>
                <strong>CTA Name:</strong> {eventData.cta_name}
              </Col>
              <Col md={6}>
                <strong>End Screen Message:</strong> {eventData.end_screen_message}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Event;
