import React, { useState, useEffect } from "react";
import axios from "axios";
import vector from "../../../../assets/image/template/Vector.png";

const LocationsLanding = ({ id, locationAddress }) => {
  useEffect(() => {
    if (id) {
      // Fetch impression count when the component mounts
      const fetchImpressionCount = async () => {
        try {
          const response = await axios.get(
            `https://web.linkoo.io:8001/api/poster/impression/${id}`
          );
          if (response?.data?.data?.count) {
            console.log("Impression Count:", response.data.data.count);
          } else {
            console.log("No impression count available for the provided ID.");
          }
        } catch (error) {
          console.error("Error fetching impression count:", error);
        }
      };

      fetchImpressionCount();
    }
  }, [id]);

  const handleButtonClick = () => {
    window.open(locationAddress, "_blank", "noopener,noreferrer");
  };

  console.log(locationAddress);

  const styles = {
    button: {
      padding: "10px 20px",
      backgroundColor: "rgb(212 54 197)",
      color: "#fff",
      border: "none",
      borderRadius: "35px",
      cursor: "pointer",
    },
    modalOverlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modalContent: {
      backgroundColor: "#fff",
      borderRadius: "10px",
      padding: "20px",
      width: "400px",
      maxHeight: "80vh",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    },
    closeButton: {
      backgroundColor: "transparent",
      border: "none",
      fontSize: "20px",
      cursor: "pointer",
    },
  };

  return (
    <div>
      <button style={styles.button} onClick={handleButtonClick}>
        Click For Directions <img src={vector} />
      </button>
    </div>
  );
};

export default LocationsLanding;
