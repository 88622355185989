import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { Col, Row } from "react-bootstrap";
import Tabs from "../../../Component/Merchant/Tabs";
import { DashTabs } from "../../../pages/Merchant/Dashboard/Utils/utils";

Chart.register(ArcElement);

const HalfDoChart = ({ staticPercentage, dynamicPercentage }) => {
  const [activeId, setActiveId] = useState(1);

  const getPercentage = () => {
    return activeId === 1 ? staticPercentage : dynamicPercentage;
  };

  const getBackgroundColor = () => {
    return activeId === 1 ? ["#5EB02B", "#E9E9E9"] : ["#E9E9E9", "#5EB02B"];
  };

  const data = {
    datasets: [
      {
        data: [staticPercentage, dynamicPercentage],
        backgroundColor: getBackgroundColor(),
        borderColor: "#D1D6DC",
      },
    ],
  };

  return (
    <div>
      <Row>
        <Col md={12} lg={18}>
          <Tabs
            values={DashTabs}
            activeId={activeId}
            setActiveId={setActiveId}
          />
        </Col>
      </Row>
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
          },
          rotation: -90,
          circumference: 180,
          cutout: "60%",
          maintainAspectRatio: true,
          responsive: true,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: "78%",
          left: "84%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div className="DMSans-Regular f-18 fw-600">{getPercentage()}%</div>
      </div>
    </div>
  );
};

export default HalfDoChart;
