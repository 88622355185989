import React from "react";
import { Col, Row } from "react-bootstrap";
import SubHead from "../../../../Component/Merchant/SubHead";
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import GroroupCode from "./GroupCode";
import { Link } from "react-router-dom";
function GroupView() {
    return (
        <>
        <SubHead head="QR code" first="Home" second="QR Code" third="Group Code" fourth="Group Code 1"/> 
        <br/>
            <Row>
                <Col>
                    <div className="left-arrow">
                        <h3 className="color-black DMSans-Regular f-18 fw-400"><img src={leftarrow} alt="leftarrow"/><Link to="/qrcode">Group Code 1</Link></h3>    
                        <h3 className="color-emperor DMSans-Regular f-14 fw-400">Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod tempor incididunt.</h3>
                    </div>
                </Col>
                <Col>
                    <div className="d-flex justify-content-end">
                        <button className="btn-download group-btn-t bgcolor-cerise">
                          <h1 className="DMSans-Regular group-btn f-18 fw-600 color-white">Edit Group code</h1>  
                        </button>
                    </div>
                </Col>
            </Row>
        <Row className="justify-content-center">
            <Col md={10}>
                <Row>
                    {Array.from({ length: 3 }, (_, index) => {
                        return (
                            <Col className="mb-3" md={4}>
                                <GroroupCode />
                            </Col>
                        );
                    })}
                </Row>
            </Col>
        </Row>
        </>
    );
}
export default GroupView;        