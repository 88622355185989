import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
import star from "../../../../assets/image/qrcode/star.svg";
import play from "../../../../assets/image/qrcode/play.svg";
import link from "../../../../assets/image/qrcode/link.svg";
import { LiaCopy } from "react-icons/lia";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

function GroupCode() {
    const navigate = useNavigate(); // Hook for navigation

    const handleViewAnalytics = () => {
        navigate("/qrcode/qranalytics"); // Navigate to the QR Code Analytics page
      };
  return (
        <div className="groupqr-layout">
            <div className="billing-plan bgcolor-snowflurry">
                <small className="Inter-Regular f-12 fw-600 color-olivedrab">
                    Active
                </small>
            </div>
            <br/>
            <div className="d-inline-flex justify-content-between">
                <div>
                    <img className="bgcolor-white" src={qrcode} alt="scanme"/>
                </div>
                <div>
                    <h3 className="color-black DMSans-Regular f-18 fw-600"> Sample QR Code Test code</h3>
                    <h3 className="color-mandalay DMSans-Regular f-14 fw-400"><img src={play} alt="play"/>30 Scans</h3>
                    <h3 className="color-emperor Inter-Regular f-14 fw-400">Updated on 12, Dec 2023 05:00 Pm</h3>
                </div>
            </div>
            <h3 className="color-emperor DMSans-Regular f-14 fw-400">Lorem ipsum dolor sit amet, consectur adipiscing elit, sed do eiusmod tempor incididunt.</h3>
            <h3 className="color-emperor DMSans-Regular f-14 fw-400">Chennai</h3>
            <h3 className="color-emperor DMSans-Regular f-14 fw-400"><img src={link} alt="link"/>linkoo.web/34meiprs.com{' '}<LiaCopy /></h3>            
            <Button
                  variant="contained"
                  className="btn-download bgcolor-cerise mt-3 mx-5"
                  onClick={handleViewAnalytics}
                >
                  View Analytics
                </Button>
        </div>
    );
}

export default GroupCode;
