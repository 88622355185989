// Information.js
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import api from "../../../../Service/Axios";
import { profile_details_settings, change_password, update_profile, delete_account } from "../../../../Service/ApiUrls";
import InputBoxHorizontal from "../../../../Component/Merchant/Forms/InputBoxHorizontal";
import { useSelector } from "react-redux";
import { InformationForm } from "../utils/utils";
import { Modal, Form } from "react-bootstrap"; // Import Modal and Form from React-Bootstrap

function Information() {
  const UserInfo = useSelector((state) => state?.profile?.profile);

  const [formValues, setFormValues] = useState({
    client_name: "",
    email_id: "",
    mobile_no: "",
    address: "",
    industry_name: "",
    role_name: "",
    // Removed current_password from formValues
  });

  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwords, setPasswords] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });

  useEffect(() => {
    if (UserInfo) {
      setFormValues({
        client_name: UserInfo.client_name || "",
        email_id: UserInfo.email_id || "",
        mobile_no: UserInfo.mobile_no || "",
        address: UserInfo.address || "",
        industry_name: UserInfo.industry_name || "",
        role_name: UserInfo.role_name || "",
      });
    }
  }, [UserInfo]);

  const handleInputChange = (key, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePasswordInputChange = (key, value) => {
    setPasswords((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleChangePassword = async () => {
    const { old_password, new_password, new_password_confirmation } = passwords;

    if (!old_password || !new_password || !new_password_confirmation) {
      alert("Please fill in all fields to change your password.");
      return;
    }

    if (new_password !== new_password_confirmation) {
      alert("New password and confirmation do not match.");
      return;
    }

    try {
      const response = await api.post(change_password, {
        old_password,
        new_password,
        new_password_confirmation,
      });

      if (response.data.status) {
        alert("Password changed successfully!");
        setPasswords({ old_password: "", new_password: "", new_password_confirmation: "" });
        setShowPasswordModal(false); // Close the modal on success
      } else {
        alert("Failed to change password. Please try again.");
      }
    } catch (error) {
      console.error("Error changing password:", error);
      alert("An error occurred while changing your password.");
    }
  };

  const handleSave = async () => {
    // Prepare the payload with necessary parameters
    const payload = {
      client_name: formValues.client_name,
      email_id: formValues.email_id,
      mobile_no: formValues.mobile_no,
      industry_id: UserInfo?.industry_id || 1, // Use existing industry_id or default to 1
      address: formValues.address,
    };

    try {
      // Make the API call to update the profile
      const response = await api.post(update_profile, payload);

      // Handle the response
      if (response.data.status) {
        alert("Profile updated successfully!");
      } else {
        alert("Failed to update profile. Please try again.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating your profile.");
    }
  };


  // const handleSave = async () => {
  //   try {
  //     const response = await api.put(profile_details_settings, formValues);
  //     if (response.data.status) {
  //       alert("Profile updated successfully!");
  //     } else {
  //       alert("Failed to update profile. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating profile:", error);
  //     alert("An error occurred while updating your profile.");
  //   }
  // };

  const openPasswordModal = () => setShowPasswordModal(true);
  const closePasswordModal = () => setShowPasswordModal(false);
  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete your account? This action cannot be undone."
    );

    if (!confirmDelete) return;

    try {
      const response = await api.get(delete_account);

      if (response.data.status) {
        alert("Account deleted successfully!");
        // Optionally redirect the user to a login or home page
        window.location.href = "login"; // Example: Replace with your actual logout or redirection logic
      } else {
        alert("Failed to delete account. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      alert("An error occurred while deleting your account.");
    }
  };
  return (
    <div className="cards">
      {/* Profile Information Section */}
      <div className="d-flex justify-content-between align-items-center border-bottom py-3">
        <div className="d-flex">
          <div className="me-2">
            <img
              src={require("../../../../assets/image/avatar.png")}
              alt="avatar"
              width={60}
            />
          </div>
          <div className="user">
            <small className="name Gilroy-semibold f-16 fw-600">
              {formValues.client_name || "Loading..."}
            </small>
            <br />
            <small className="Gilroy-regular user-mail f-14 fw-500">
              {formValues.role_name || "Loading..."}
            </small>
          </div>
        </div>
        <Button
          variant="contained"
          className="btn-download bgcolor-cerise"
          onClick={handleSave}
        >
          Save
        </Button>
      </div>

      {/* Form Fields Section */}
      <div className="border-bottom py-3">
        {InformationForm.map((item, i) => (
          <InputBoxHorizontal
            key={i}
            label={item.label}
            type={item.type}
            placeholder={item.placeholder}
            value={formValues[item.key_name]}
            onhandlechange={(e) =>
              handleInputChange(item.key_name, e.target.value)
            }
            onChangePassword={item.label === "Current Password" ? openPasswordModal : null}
          />
        ))}
      </div>

      {/* Delete Account Section */}
      <div>
        <small className="d-block f-16 fw-600 DMSans-Regular color-black mt-3">
          Delete account
        </small>
        <ul>
          <li className="f-14 fw-400 Inter-Regular color-emperor mt-3">
            Lorem ipsum dolor sit amet, consectet adipiscing elit, sed do
            eiusmod tempor incididunt.
          </li>
        </ul>
        <Button
          variant="contained"
          className="btn-download bgcolor-cerise"
          onClick={handleDeleteAccount}
        >
          Delete my account
        </Button>
      </div>

      {/* Password Change Modal */}
      <Modal show={showPasswordModal} onHide={closePasswordModal}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Old Password Field */}
            <Form.Group controlId="formOldPassword">
              <Form.Label>Old Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your old password"
                value={passwords.old_password}
                onChange={(e) =>
                  handlePasswordInputChange("old_password", e.target.value)
                }
              />
            </Form.Group>

            {/* New Password Field */}
            <Form.Group controlId="formNewPassword" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter your new password"
                value={passwords.new_password}
                onChange={(e) =>
                  handlePasswordInputChange("new_password", e.target.value)
                }
              />
            </Form.Group>

            {/* Confirm New Password Field */}
            <Form.Group controlId="formConfirmNewPassword" className="mt-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Confirm your new password"
                value={passwords.new_password_confirmation}
                onChange={(e) =>
                  handlePasswordInputChange("new_password_confirmation", e.target.value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>

          <Button
            variant="contained"
            className="btn-download bgcolor-cerise"
            onClick={handleChangePassword}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Information;
