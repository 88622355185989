import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../../assets/image/template/arrow-left.png";
import logo from "../../../../assets/image/template/Logo.svg";
import documentIcon from "../../../../assets/image/download.svg";

const DocumentsList = ({ setSwaps, document }) => {
  const navigate = useNavigate();
  const baseUrl = "https://linkoo.s3.amazonaws.com//";

  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setCurrentTime(
        now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleBackClick = () => {
    navigate("/appoimentLanding");
  };

  const documentStyles = {
    container: {
      textAlign: "center",
    },
    grid: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px",
      marginTop: "20px",
      overflowY: "auto",
      maxHeight: "500px",
      paddingRight: "10px",
    },
    item: {
      backgroundColor: "#f5f5f5",
      border: "1px solid #ddd",
      borderRadius: "8px",
      padding: "20px",
      width: "auto",
      textAlign: "center",
      position: "relative",
    },
    content: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "30px", 
      width: "100%",
    },
    downloadIcon: {
      width: "24px",
      height: "24px",
      cursor: "pointer",
    },
    downloadButton: {
      display: "inline-block",
      marginTop: "10px",
      padding: "8px 12px",
      backgroundColor: "#007bff",
      color: "#fff",
      textDecoration: "none",
      borderRadius: "4px",
    },
  };

  return (
    <div className="container-landing">
      <div className="video-banner" style={{ borderTop: "1px solid #f3f3f3" }}>
        <br />
        <div className="left-section">
          <img
            src={backArrow}
            alt="Back Arrow"
            className="back-arrow"
            onClick={() => setSwaps(false)}
            style={{
              cursor: "pointer",
              marginRight: "10px",
              verticalAlign: "middle",
            }}
          />
        </div>
        <div className="contentCenter">
          <div style={documentStyles.container}>
            <p>Here are your documents:</p>
            <div style={documentStyles.grid}>
              {document.map((doc, index) => (
                <div style={documentStyles.item} key={index}>
                  <div style={documentStyles.content}>
                    <p style={{ margin: 0 }}>{doc.document || "Document"}</p>
                    <a
                      href={`${baseUrl}${doc.document}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={documentIcon}
                        alt="Download Icon"
                        style={documentStyles.downloadIcon}
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <footer>
        <div className="footer-powered">
          <span>Powered by</span>
          <img src={logo} alt="Powered by Logo" />
        </div>
      </footer>
    </div>
  );
};

export default DocumentsList;
