import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import status from "../../../../assets/image/Landing/status-bar.png";
import logo from "../../../../assets/image/template/Logo.svg";
import "../../../../assets/Css/landing.css";
import round from "../../../../assets/image/template/round.png";
import roundTwo from "../../../../assets/image/template/roundTwo.png";
import backArrow from "../../../../assets/image/template/arrow-left.png";

const SubmittedPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { formData } = location.state || {};

    const [currentTime, setCurrentTime] = useState("");
    console.log("form", formData)

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setCurrentTime(
                now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleBackClick = () => {
        navigate("/appoimentLanding");
    };

    if (!formData) {
        return <p>Loading...</p>;
    }

    const date_and_time = formData.data?.appoinments?.date_and_time || 0;


    return (
        <div className="container-landing">
            <div className="status-bar">
                <div className="left-section">{currentTime}</div>
                <div className="right">
                    <img src={status} alt="Status Bar Icon" className="icon" />
                </div>
            </div>

            <div className="small-image-row">
                <img src={round} alt="Image 1" className="rounded-image" />
                <img src={roundTwo} alt="Image 2" className="rounded-image" />
                <img src={round} alt="Image 3" className="rounded-image" />
                <img src={round} alt="Image 4" className="rounded-image" />
            </div>

            <div className="video-banner" style={{ borderTop: "1px solid #f3f3f3" }}>
                <br />
                <div className="left-section">
                    <img
                        src={backArrow}
                        alt="Back Arrow"
                        className="back-arrow"
                        onClick={handleBackClick}
                        style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            verticalAlign: "middle",
                        }}
                    />
                </div>
                <div className="contentCenter">
                    <div className="detailsBox">
                        <h2 className="detailsHeading">Appointment Details</h2>
                        <div className="detailsRow">
                            <span>Date and Time</span>
                        </div>
                        <div className="detailsRow">
                        <span>{date_and_time || "Not provided"}</span>

                        </div>
                    </div>

                    <p className="thankYouMessage">
                        Thanks for booking an appointment with us! <br />
                        Our team will review and contact you shortly!
                    </p>
                    <div>
                        <p className="contactInfo">For further details</p>
                        <p className="contactInfo">+91 9803937490</p>
                        <p className="contactInfo">www.abc_company.com</p>
                    </div>
                </div>
            </div>

            <footer>
                <div className="footer-powered">
                    <span>Powered by</span>
                    <img src={logo} alt="Powered by Logo" />
                </div>
            </footer>
        </div>
    );
};

export default SubmittedPage;
