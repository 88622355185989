import React, { useEffect, useState, useRef } from "react";
import { Col, Row, Dropdown, Table } from "react-bootstrap";  // Importing Dropdown from react-bootstrap
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import qrcode from "../../../../assets/image/qrcode/qrcode.svg";
import star from "../../../../assets/image/qrcode/star.svg";
import play from "../../../../assets/image/qrcode/play.svg";
import link from "../../../../assets/image/qrcode/link.svg";
import copy from "../../../../assets/image/qrcode/copy.svg";
import SubHead from "../../../../Component/Merchant/SubHead";
import { LiaCopy } from "react-icons/lia";
import { Link } from "react-router-dom";
import { qr_analysis } from "../../../../Service/ApiUrls";
import api from "../../../../Service/Axios";
import { useParams } from "react-router-dom";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts"; // Import recharts components


function IndividualQRCodeAnalytics() {
    const { id } = useParams(); // Get the id from the URL
    const [qrData, setQrData] = useState(null); // Store the QR code data
    const [loading, setLoading] = useState(true); // Track loading state
    const downloadLinkRef = useRef(null); // Reference to the download link

    useEffect(() => {
        // Fetch the QR code analysis data from the API based on the id
        const fetchQRData = async () => {
            try {
                const response = await api.get(`${qr_analysis}/${id}`);
                console.log("API Response:", response);

                setQrData(response.data); // Assuming the response is in the shape {data: { ... }}
                setLoading(false);
            } catch (error) {
                console.error("Error fetching QR code data:", error);
                setLoading(false);
            }
        };

        if (id) {
            fetchQRData();
        }
    }, []);

    console.log("QR Data:", qrData);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!qrData) {
        return <div>Error: No QR data available.</div>;
    }

    // Safely accessing the qrcode_name
    const qrcodeName = qrData.data?.qrcode_detail?.qrcode_name || "QR Code Name Not Available";
    const description = qrData.data?.qrcode_detail?.description || "QR Code description Not Available";
    const updatedat = qrData.data?.qrcode_detail?.updated_at || "QR Code updated at Not Available";
    const lat_long = qrData.data?.qrcode_detail?.lat_long || "QR Code lat and long Not Available"
    const locationName = (qrData.data?.qrcode_detail?.location_name) || "QR Code Location Name Not Available";
    const qrcodeType = qrData.data?.qrcode_detail?.qrcode_type || "QR Code Type Not Available"

    // Construct the full URL for the QR code image
    const qrImageURL = `https://linkoo.s3.amazonaws.com/qrcodes/${qrData.data?.qrcode_detail?.qrcode_image}`;
    const qrcode_image = qrData.data?.qrcode_detail?.qrcode_image || "QR Code link Not Available"

    // Construct the full URL for the poster image
    const posterImageURL = `https://linkoo.s3.amazonaws.com/banners/${qrData.data?.qrcode_detail?.poster_image}`;
    const totalScans = qrData.data?.performance_by_date?.counts;

    // Download the poster image when an option is selected from the dropdown
    const handleDownload = (fileType) => {
        const link = downloadLinkRef.current;
        let fileUrl = posterImageURL;

        if (fileType === "qr") {
            fileUrl = qrImageURL;  // If 'qr' is selected, download the QR image instead
        }

        link.href = fileUrl;
        link.download = qrData.data.qrcode_detail[fileType === "qr" ? "qrcode_image" : "poster_image"];
        link.click(); // Trigger the download
    };
    const performanceByDate = qrData.data?.performance_by_date || { dates: [], counts: [] };
    const graphData = performanceByDate.dates.map((date, index) => ({
        date,
        scans: performanceByDate.counts[index] || 0,
    }));

    const mobileDevices = qrData.data?.mobile_devices?.android || 0;
    const mobileDevicesiphones = qrData.data?.mobile_devices?.iphones || 0;
    const mobileDevicesOthers = qrData.data?.mobile_devices?.others || 0;


    const copyToClipboard = () => {
        navigator.clipboard.writeText(qrcode_image)
            .then(() => {
                alert("QR code link copied to clipboard!"); // Optional: Show a success message
            })
            .catch(err => {
                console.error("Failed to copy: ", err);
            });
    };


    return (
        <>
            <SubHead head="QR code" first="Home" second="QR Code" third="All Codes" fourth={qrcodeName} />
            <br />
            <div className="left-arrow">
                <h3 className="color-black DMSans-Regular f-18 fw-400">
                    <img src={leftarrow} alt="leftarrow" />
                    <Link to="/qrcode">{qrcodeName}</Link>
                </h3>
            </div>

            <Row className="mt-3">
                <Col xs={12} md={8} lg={8}>
                    <div className="individualqr-layout">
                        <div className="text-update">
                            <p className="color-emperor Inter-Regular f-12 fw-400">Updated on {updatedat}</p>
                        </div>
                        <div className="billing-plan bgcolor-snowflurry">
                            <small className="Inter-Regular f-12 fw-600 color-olivedrab">Active</small>
                        </div>
                        <div className="d-flex justify-content-between">
                            <div>
                                {/* Update this img tag to use the dynamic image URL */}
                                <img
                                    className="qr-layout bgcolor-white"
                                    src={qrImageURL}
                                    alt="scanme"
                                    style={{ width: "150px", height: "auto" }} // Adjust the size as needed
                                />
                            </div>
                            <div className="text-sample">
                                <h3 className="color-black DMSans-Regular f-18 fw-600">{qrcodeName}<span>{'  '}<img src={star} alt="star" /></span></h3>
                                <h3 className="color-mandalay DMSans-Regular f-14 fw-400">
                                    <img src={play} alt="play" />{qrData.totalScans || 0} Scans
                                </h3>
                                <h3 className="color-emperor DMSans-Regular f-14 fw-400">{description}</h3>
                                <h3 className="color-emperor DMSans-Regular f-14 fw-400">{locationName || lat_long}</h3>
                            </div>

                            {/* Dropdown to select the download option */}
                            <Dropdown >
                                <Dropdown.Toggle variant="primary" id="dropdown-download"
                                    style={{
                                        width: "145px",
                                        backgroundColor: "#d235c3",
                                        border: "none",
                                        borderRadius: "106px",
                                        marginRight: "53px",
                                        marginBottom: "-86px"
                                    }}>
                                    Download
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => handleDownload("poster")}>Download Poster</Dropdown.Item>
                                    {/* <Dropdown.Item onClick={() => handleDownload("qr")}>Download QR Code</Dropdown.Item> */}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <h3 className="color-emperor DMSans-Regular f-14 fw-400 text-update"><img src={link} alt="link" />{qrcode_image}{' '}<LiaCopy onClick={copyToClipboard} /></h3>
                    </div>
                </Col>
            </Row>
            <br />

            <Row className="mt-3 mb-3">
                <Col className="mb-3" md={12} lg={9}>
                    <Row>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-snowflurry">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-olivedrab">{qrcodeType}</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">QR Code Category</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-pinklace">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-cerise">{totalScans || 0}</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">Total Scans</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-sandybeach">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-mandalay">{qrData.uniqueScans || 0}</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">Unique Scans</h5>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} md={6} lg={3}>
                            <div className="box-individual bgcolor-bluechalk">
                                <div className="p-3">
                                    <h5 className="DMSans-Regular f-48 fw-500 color-purpleheart">{qrData.impressions || 0}</h5>
                                </div>
                                <div className="box-half bgcolor-white">
                                    <h5 className="DMSans-Regular f-18 fw-500 color-black">No. of Clicks</h5>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>

                <Col md={6}>
                    <div className="performance-layout">

                        <h5>Link Performance by Date</h5>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={graphData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="scans" stroke="#8884d8" activeDot={{ r: 8 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                </Col>

                <Col md={6}>
                    <div className="performance-layout">

                        <h5>Mobile Device Performance</h5>
                        <Table borderless>
                            <thead>
                                <tr>
                                    <th>Device Name</th>
                                    <th>Views</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Android</td>
                                    <td>{mobileDevices || 0}</td>
                                </tr>
                                <tr>
                                    <td>iphones</td>
                                    <td>{mobileDevicesiphones || 0}</td>
                                </tr>
                                <tr>
                                    <td>Others</td>
                                    <td>{mobileDevicesOthers || 0}</td>
                                </tr>
                                {/* {mobileDevices.length > 0 ? (
                                    mobileDevices.map((device, index) => (
                                        <tr key={index}>
                                            <td>{device.device_name || "Unknown Device"}</td>
                                            <td>{device.views || 0}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="2">No data available</td>
                                    </tr>
                                )} */}
                            </tbody>

                        </Table>
                    </div>
                </Col>
            </Row>

            {/* Hidden download link */}
            <a ref={downloadLinkRef} style={{ display: "none" }} />
        </>
    );
}

export default IndividualQRCodeAnalytics;
