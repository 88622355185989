import axios from "axios";
import { baseUrl, baseLandingUrl } from "../Config";
import { toast } from "react-toastify";

const apiLanding = axios.create({
    baseURL: baseLandingUrl,
  });

 
// Add a request interceptor
//request
apiLanding.interceptors.request.use(
function (config) {
// console.log(config);
 
config.headers = {
"Content-Type": "application/json",
// Authorization: `Bearer ${localStorage.getItem("token")}`,
};
 
return config;
},
function (error) {
// Do something with request error
 
return Promise.reject(error);
}
);
//response
// Add a response interceptor
apiLanding.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
  
    return Promise.reject(error);
  }
);

export default apiLanding;