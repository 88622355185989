import React, { useEffect } from 'react';
import axios from 'axios';

const SocialMediaLinks = ({ links, id }) => {
    useEffect(() => {
        if (id) {
            // Fetch impression count when the component mounts
            const fetchImpressionCount = async () => {
                try {
                    const response = await axios.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                    if (response?.data?.data?.count) {
                        console.log("Impression Count:", response.data.data.count);
                    } else {
                        console.log("No impression count available for the provided ID.");
                    }
                } catch (error) {
                    console.error("Error fetching impression count:", error);
                }
            };

            fetchImpressionCount();
        }
    }, [id]);

    if (!links || links.length === 0) {
        return <p>No social media links available.</p>;
    }

    return (
        <div className="social-media-links">
            {links.map((link, index) => (
                <a
                    key={index}
                    href={link.link} // The URL from the social media link object
                    target="_blank" // Opens in a new tab
                    rel="noopener noreferrer" // Security and performance
                    className="social-media-link"
                >
                    <img
                        src={`https://linkoo.s3.amazonaws.com/${link.logo}`} // Logo image
                        alt={link.social_media_name || "Social Media"} // Alt text
                        className="social-media-logo"
                        style={{ width: "30px", height: "30px", marginRight: "10px" }}
                    />
                    {/* {link.social_media_name || "Social Media"} */}
                </a>
            ))}
        </div>
    );
};

export default SocialMediaLinks;
