import React from "react";
import Table from "react-bootstrap/Table";
import CircularProgress from "@mui/material/CircularProgress";

function WarrantyTable({ data, loading, error }) {
  if (loading) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  }

  if (error) {
    return <p>Error loading data: {error.message}</p>;
  }

  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>User Name</th>
            <th>Email Id</th>
            <th>Mobile Number</th>
            <th>Date and Time</th>
            <th>Code Type</th>
            <th>Code Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((value, key) => (
              <tr key={key}>
                <td className="f-16 fw-500 tableText">{value.username}</td>
                <td className="f-16 fw-400 tableText">{value.mailid}</td>
                <td className="f-16 fw-400 tableText">{value.mobileno}</td>
                <td className="f-16 fw-400 tableText">{value.datetime}</td>
                <td className="f-16 fw-400 tableText">{value.codetype}</td>
                <td className="f-16 fw-400 tableText">{value.codename}</td>
                <td className="f-16 fw-400 tableText">{value.status}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" className="text-center">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}

export default WarrantyTable;
