export const postLogin = `login`;
export const industries_list = `industries_list`;
export const subscriptions_list = `subscriptions_list`;
export const signup = `signup`;
export const forgotpassword = `forgot-password`;
export const verifyotp = `verify-otp`;
export const resetpassword = `reset-password`;
export const profile_details = `settings/profile_details`;

//qrcode apis
export const qr_code_list = `qr_code_list`;
export const archived_qr_code_list = `archived_qr_code_list`;
export const search = `qr_code/search`;
export const cta_categories = `cta_categories`;
export const qr_code_delete = `qr_code/delete`;
export const qr_code_detail = `qr_code_detail`;
export const locations = `locations`;
export const check_draft_status = `check_draft_status`;
export const qr_code_content = `qr_code/content/`; //id
export const qr_code_design = `qr_code/design/`; //id

export const social_media_types = "social_media_types";
export const getPosters = "posters";

export const getQrCodeDetailsByID = "qr_code/detail/"; //id
export const statistics_appointments = `statistics/appointments`
export const templates = `templates`
export const getPreSignedUrl = `getPreSignedUrl`
export const createLocation = `location`
// export const delete = `qr_code/delete`; //id
export const location_edit = `location`
export const location_delete = `location/delete`
export const qrcode_edit = `qr_code/edit`
export const get_qrdetails = `get_qrdetails`
export const qr_analysis = `qr_analysis`
export const social_media = `impression`
export const register_event = `register_event`
export const submit_feedback = `submit_feedback`
export const export_appointments = `statistics/export_appointments`
export const feedbacks_eve = `statistics/feedbacks`
export const events = `statistics/events`
export const export_events = `statistics/export_events`
export const export_feedback = `statistics/export_feedback`
export const warranty_activations = `statistics/warranty_activations`
export const profile_details_settings = `settings/profile_details`
export const change_password = `settings/change_password`
export const update_profile = `settings/update_profile`
export const delete_account =  `settings/delete_account`
export const user_create = `user/create`
export const active_roles = `active-roles`
export const users = `users`
export const user_edit = `user/edit`
export const update_user = `user`
export const delete_user = `user/delete`
export const role = `role`
export const roles = `roles`
export const role_edit = `role`
export const role_update = `role`
export const dashboard = `dashboard`
export const role_search = `role/search`
export const role_delete = `role/delete`
export const dashboard_stats = `dashboard/stats`
export const activate_warrenty = `activate_warrenty`
export const logout = `logout`