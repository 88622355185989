import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import Table from "./AppointmentTable"; // Assuming AppointmentTable component is present
import SearchBox from "../../../../Component/Merchant/SearchBox"; // Importing your SearchBox component
import api from "../../../../Service/Axios"; // Your Axios instance
import { export_appointments, statistics_appointments } from "../../../../Service/ApiUrls"; // API URLs
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown"; // Import your Dropdown component

function Appointment() {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [qrcodeNames, setQrcodeNames] = useState([]);
  const [qrcodeTypes, setQrcodeTypes] = useState([]);
  const [selectedQrcodeName, setSelectedQrcodeName] = useState("");
  const [selectedQrcodeType, setSelectedQrcodeType] = useState("");

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await api.post(statistics_appointments);
        if (response.data.data.appointments) {
          setAppointments(response.data.data.appointments);
          setFilteredAppointments(response.data.data.appointments);
        }

        const qrResponse = await api.post(statistics_appointments);
        if (qrResponse.data.data.appointments) {
          const qrCodes = qrResponse.data.data.appointments;
          const qrNames = qrCodes.map(appointment => appointment.qrcode_name);
          const qrTypes = qrCodes.map(appointment => appointment.qrcode_type);

          setQrcodeNames([...new Set(qrNames)]);
          setQrcodeTypes([...new Set(qrTypes)]);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching appointments or QR codes:", error);
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchTerm(query);
    filterAppointments(query, selectedQrcodeName, selectedQrcodeType);
  };

  const handleQrcodeNameChange = (e) => {
    const selectedName = e.target.value;
    setSelectedQrcodeName(selectedName);
    filterAppointments(searchTerm, selectedName, selectedQrcodeType);
  };

  const handleQrcodeTypeChange = (e) => {
    const selectedType = e.target.value;
    setSelectedQrcodeType(selectedType);
    filterAppointments(searchTerm, selectedQrcodeName, selectedType);
  };

  const filterAppointments = (searchTerm, qrcodeName, qrcodeType) => {
    let filtered = appointments.filter(appointment =>
      appointment.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      appointment.email_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      appointment.mobile_no.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (qrcodeName) {
      filtered = filtered.filter(appointment => appointment.qrcode_name === qrcodeName);
    }

    if (qrcodeType) {
      filtered = filtered.filter(appointment => appointment.qrcode_type === qrcodeType);
    }

    setFilteredAppointments(filtered);
  };

  const handleExport = async () => {
    try {
      const response = await api.post(export_appointments, null, {
        responseType: "blob", // Ensures the response is treated as a binary blob
      });
  
      // Create a Blob object from the response
      const blob = new Blob([response.data], { type: "text/csv" });
  
      // Create a link element
      const link = document.createElement("a");
  
      // Set the URL to the Blob
      const url = window.URL.createObjectURL(blob);
      link.href = url;
  
      // Set the file name
      link.download = "appointments.csv";
  
      // Append the link to the body (not visible to the user)
      document.body.appendChild(link);
  
      // Programmatically click the link
      link.click();
  
      // Cleanup: Remove the link and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting appointments:", error);
    }
  };
  

  // const handleExport = async () => {
  //   try {
  //     const response = await api.post(export_appointments, null, {
  //       responseType: "blob", // Ensures the response is treated as a binary blob
  //     });

  //     // Create a Blob object from the response
  //     const blob = new Blob([response.data], {
  //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  //     });

  //     // Create a link element
  //     const link = document.createElement("a");

  //     // Set the URL to the Blob
  //     const url = window.URL.createObjectURL(blob);
  //     link.href = url;

  //     // Set the file name
  //     link.download = "appointments.xlsx";

  //     // Append the link to the body (not visible to the user)
  //     document.body.appendChild(link);

  //     // Programmatically click the link
  //     link.click();

  //     // Cleanup: Remove the link and revoke the object URL
  //     document.body.removeChild(link);
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //     console.error("Error exporting appointments:", error);
  //   }
  // };

  // if (loading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <React.Fragment>
      <Row>
        <Col md={6} lg={4}>
          <SearchBox key_name="search" value={searchTerm} onhandlechange={handleSearchChange} />
        </Col>

        <Col md={12} lg={8}>
          <Dropdown
            list={qrcodeNames.map(name => ({ value: name, label: name }))}
            value={selectedQrcodeName}
            onhandlechange={handleQrcodeNameChange}
            placeholder="Select QR Code Name"
          />

          <Dropdown
            list={qrcodeTypes.map(type => ({ value: type, label: type }))}
            value={selectedQrcodeType}
            onhandlechange={handleQrcodeTypeChange}
            placeholder="Select QR Code Type"
          />

          <Button
            variant="contained"
            className="btn"
            style={{
              backgroundColor: "white",
              color: "black",
              border: "1px solid black",
              borderRadius: "50px",
              float: "right",
            }}
            onClick={handleExport}
          >
            <b>Export</b>
          </Button>
        </Col>
      </Row>

      <Table appointments={filteredAppointments} loading={loading}/>
    </React.Fragment>
  );
}

export default Appointment;
