import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../../assets/image/template/arrow-left.png";
import logo from "../../../../assets/image/template/Logo.svg";

const GalleryList = ({ setSwaps, gallery }) => {
    const navigate = useNavigate();
    const baseUrl = "https://linkoo.s3.amazonaws.com//";

    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setCurrentTime(
                now.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
            );
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const handleBackClick = () => {
        navigate("/appoimentLanding");
    };

    const documentStyles = {
        container: {
            textAlign: "center",
        },
        grid: {
            display: "grid",             // Use CSS Grid
            gridTemplateColumns: "repeat(2, 1fr)",  // Two columns layout
            gap: "30px",  // Space between items
            marginTop: "20px",
            overflowY: "auto",  // Enable vertical scrolling
            maxHeight: "500px", // Limit the height, making it scrollable when content overflows
            paddingRight: "10px", // Optional: Adjust if you want a little space for the scroll bar
        },
        
        content: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
        },
        downloadButton: {
            display: "inline-block",
            marginTop: "10px",
            padding: "8px 12px",
            backgroundColor: "#007bff",
            color: "#fff",
            textDecoration: "none",
            borderRadius: "4px",
        },
        downloadButtonHover: {
            backgroundColor: "#0056b3",
        },
    };

    return (
        <div className="container-landing">
            <div className="video-banner" style={{ borderTop: "1px solid #f3f3f3" }}>
                <br />
                <div className="left-section">
                    <img
                        src={backArrow}
                        alt="Back Arrow"
                        className="back-arrow"
                        onClick={() => setSwaps(false)}
                        style={{
                            cursor: "pointer",
                            marginRight: "10px",
                            verticalAlign: "middle",
                        }}
                    />
                </div>
                <div className="contentCenter">
                    <div style={documentStyles.container}>
                        <p>Here are your documents:</p>
                        <div style={documentStyles.grid}>
                            {gallery && gallery.length > 0 ? (
                                gallery.map((doc, index) => (
                                    <div key={index} style={documentStyles.item}>
                                        <div style={documentStyles.content}>
                                            <img
                                                src={`${baseUrl}${doc.image}`}
                                                alt={doc.name}
                                                style={{
                                                    width: "100%",
                                                    height: "auto",
                                                    borderRadius: "8px",
                                                }}
                                            />
                                           
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No documents available.</p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GalleryList;
