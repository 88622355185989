import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Table from "./WarrantyTable";
import { Button } from "@mui/material";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import api from "../../../../Service/Axios";
import { activate_warrenty } from "../../../../Service/ApiUrls";

function Warranty() {
  const [codeName, setCodeName] = useState([
    { label: "Code Name", value: "" },
    { label: "Sample", value: "Sample" },
    { label: "Test", value: "Test" },
  ]);
  const [codeType, setCodeType] = useState([
    { label: "Code Type", value: "" },
    { label: "Static", value: "Static" },
    { label: "Dynamic", value: "Dynamic" },
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCodeName, setSelectedCodeName] = useState("");
  const [selectedCodeType, setSelectedCodeType] = useState("");
  const [allcode, setAllcode] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await api.post(activate_warrenty, {
        qrcode_id: 3,
        full_name: searchQuery,
        email_id: selectedCodeName, // Assuming you want to use selectedCodeName for email_id
      });
      setAllcode(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <React.Fragment>
        <br />
        <div>
          <Row>
            <Col md={6} lg={4}>
              <SearchBox value={searchQuery} onhandlechange={(e) => setSearchQuery(e.target.value)} />
            </Col>
            <Col md={12} lg={8}>
              <Dropdown list={codeName} value={selectedCodeName} onChange={(e) => setSelectedCodeName(e.target.value)} />
              <Dropdown list={codeType} value={selectedCodeType} onChange={(e) => setSelectedCodeType(e.target.value)} />
              <Button
                variant="contained"
                className="btn"
                style={{
                  backgroundColor: "white",
                  color: "black",
                  border: "1px solid black",
                  borderRadius: "50px",
                  float: "right",
                }}
                onClick={handleSearch}
              >
                <b>Export</b>
              </Button>
            </Col>
          </Row>
        </div>
        <Table data={allcode} loading={loading} error={error} />
      </React.Fragment>
    </>
  );
}

export default Warranty;
