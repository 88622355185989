import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import axios from "axios";
import api from "../../../Service/Axios";
import { dashboard_stats } from "../../../Service/ApiUrls";

function ChartBox(props) {
  const { element, title, chartType } = props;
  const [staticActiveQR, setStaticActiveQR] = useState(0);
  const [staticInactiveQR, setStaticInactiveQR] = useState(0);
  const [dynamicActiveQR, setDynamicActiveQR] = useState(0);
  const [dynamicInactiveQR, setDynamicInactiveQR] = useState(0);

  useEffect(() => {
    const fetchQRCodeStats = async () => {
      try {
        const response = await api.get(dashboard_stats);
        if (response.data.status) {
          const data = response.data.data;
          setStaticActiveQR(data.static_active_qr);
          setStaticInactiveQR(data.static_inactive_qr);
          setDynamicActiveQR(data.dynamic_active_qr);
          setDynamicInactiveQR(data.dynamic_inactive_qr);
        }
      } catch (error) {
        console.error("Error fetching QR code stats:", error);
      }
    };

    fetchQRCodeStats();
  }, []);

  const renderActiveInactive = () => {
    if (chartType === "fullDonut") {
      return (
        <>
          <div className="d-flex align-items-center">
            <div className="status-circle active mr-2"
              style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "var(--toastify-color-success)", padding: "0px 5px" }}>
            </div>
            <p className="mb-0">&nbsp; Active</p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className="status-circle inactive mx-2"
              style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "#d235c3", padding: "0px 5px" }}>
            </div>
            <p className="mb-0"> Inactive</p>
          </div>
          <div className="d-flex align-items-center">
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>{staticActiveQR + dynamicActiveQR}</p>
            <p style={{ fontSize: "18px", fontWeight: "bold", paddingLeft: "83px" }}>{staticInactiveQR + dynamicInactiveQR}</p>
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="cards h-100">
      <div className="d-flex justify-content-between">
        <h3 className="f-18 fw-600 DMSans-Regular">{title}</h3>
        <BsThreeDotsVertical className="c-blue cursor" />
      </div>
      <div className="py-1">{element}</div>
      {renderActiveInactive()}
    </div>
  );
}

export default ChartBox;
