import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import QRChart from "../../../Component/Merchant/Charts/QRChart";
import PerformChart from "../../../Component/Merchant/Charts/PerformChart";
import SubHead from "../../../Component/Merchant/SubHead";
import LineGraph from "../../../Component/Merchant/Charts/LineGraph";
import HalfDonutchart from "../../../Component/Merchant/Charts/HalfDonutChart";
import Giftbox from "../../../assets/image/dashboard/giftbox.png";
import Table from "../../../pages/Merchant/Dashboard/DashboardTable";
import Donutchart from "../../../Component/Merchant/Charts/DonutChart";
import { dashboard } from "../../../Service/ApiUrls";
import api from "../../../Service/Axios";
import ReLineChart from "../../../Component/Merchant/Charts/AreaChart";
import DoughnutDemo from "../../../Component/Merchant/Charts/DonutChart";
import ChartBox from "../../../Component/Merchant/Charts/QRChart";
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const [totalQRCodes, setTotalQRCodes] = useState(0);
  const [totalStaticQRCodes, setTotalStaticQRCodes] = useState(0);
  const [totalDynamicQRCodes, setTotalDynamicQRCodes] = useState(0);
  const [staticPercentage, setStaticPercentage] = useState(0);
  const [dynamicPercentage, setDynamicPercentage] = useState(0);
  const [topLinks, setTopLinks] = useState([]);
  const [topLinksChart, setTopLinksChart] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get(dashboard);
        if (response.data.status) {
          const data = response.data.data;
          setTotalQRCodes(data.total_qrcodes);
          setTotalStaticQRCodes(data.total_static_qrcodes);
          setTotalDynamicQRCodes(data.total_dynamic_qrcodes);

          const staticData = data.scanned_data.find(item => item.type === 'static');
          const dynamicData = data.scanned_data.find(item => item.type === 'dynamic');

          if (staticData) setStaticPercentage(staticData.percentage);
          if (dynamicData) setDynamicPercentage(dynamicData.percentage);

          // Fetch top links data
          setTopLinks(data.top_links);

          // Fetch top links chart data
          setTopLinksChart(data.top_links_chart);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);


  const navigate = useNavigate();

  const handleUpgradeClick = () => {
    // Navigate to the availablePlans path
    navigate('/availablePlans');
  };

  return (
    <>
      <SubHead head="Dashboard" first="Home" />
      <Row className="mt-3 mb-3">
        <Col className="mb-3" md={12} lg={8}>
          <Row>
            <Col xs={12} md={4} lg={4}>
              <div className="box-dash bg-lightGreen">
                <h5 className="f-18 fw-400 box-title">Total Created QR codes</h5>
                <h3 className="box-text">
                  {totalQRCodes}
                  <span className="box-text1">/270</span>
                </h3>
              </div>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <div className="box-dash">
                <h5 className="f-18 fw-400 box-title">Total Static QR codes</h5>
                <h3 className="box-text">
                  {totalStaticQRCodes}
                  <span className="box-text1">/160</span>
                </h3>
              </div>
            </Col>
            <Col xs={12} md={4} lg={4}>
              <div className="box-dash">
                <h5 className="f-18 fw-400 box-title">Total Dynamic QR codes</h5>
                <h3 className="box-text">
                  {totalDynamicQRCodes}
                  <span className="box-text1">/110</span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row className="mt-3 mb-3">
            <Col className="mb-3" md={12} lg={12}>
              <QRChart element={<LineGraph />} title="Overall Customer’s Rate" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-3" md={8} lg={6}>
              <PerformChart element={<DoughnutDemo />} title="QR code Stats" chartType="fullDonut" />
            </Col>
            <Col className="mb-3" md={8} lg={6}>
              <div className="cards h-100">
                <img className="img-center" src={Giftbox} alt="" width={"260px"} height={"260px"} />
                <h3 className="fw-600 f-16 DMSans-Regular">
                  Get your Customizable QR code creation with our existing customer plan offers!
                </h3>
                <button
                  className="btn-download bgcolor-cerise"
                  style={{ marginTop: '20px' }}
                  onClick={handleUpgradeClick} // Add the onClick handler
                >
                  <h1 className="DMSans-Regular f-18 fw-600 color-white p-2">
                    Click to upgrade
                  </h1>
                </button>
                
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12} lg={4}>
          <Row className="mb-1">
            <Col className="mb-3" md={6} lg={12}>
              <PerformChart
                element={<HalfDonutchart staticPercentage={staticPercentage} dynamicPercentage={dynamicPercentage} />}
                title="QR code overall scanned Rate"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="mb-3" md={6} lg={12}>
              <div className="cards h-100">
                <h4 className="DMSans-Regular f-18 fw-600">My Top Links</h4>
                {/* Pass topLinks data to Table component */}
                <Table topLinks={topLinks} />
                <ReLineChart chartData={topLinksChart} />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Dashboard;
