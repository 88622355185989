import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/Merchant/Auth/Login";
import SignUp from "../pages/Merchant/Auth/SignUp";
import { SidebarDataMerchant } from "../Layout/SidebarData";
import Sidebar from "../Layout/Sidebar";
import { Provider } from "react-redux";
import NotificationIcon from "../pages/Merchant/Notifications/index";
import Dashboard from "../pages/Merchant/Dashboard/index";
import StaticQRCode from "../pages/Merchant/QrCode/Static";
import DynamicQRCode from "../pages/Merchant/QrCode/Dynamic";
import FavoriteQRCode from "../pages/Merchant/QrCode/Favorite";
import ArchivedQRCode from "../pages/Merchant/QrCode/Archived";
import Template from "../pages/Merchant/Templates/index";
import SocialMedia from "../pages/Merchant/Statistics/SocialMedia";
import Appointments from "../pages/Merchant/Statistics/Appointment";
import Feedback from "../pages/Merchant/Statistics/Feedback";
import IndividualQR from "../pages/Merchant/QrCode/IndividualQRCode";
import Statistics from "../pages/Merchant/Statistics/index";
import Billing from "../pages/Merchant/Billing";
import QRCodeAnalytics from "../pages/Merchant/QrCode/Analytics";
import Forms from "../pages/Merchant/Statistics/Forms";
import Gamification from "../pages/Merchant/Statistics/Gamification";
import QRcode from "../pages/Merchant/QrCode";
import GroupQRCode from "../pages/Merchant/QrCode/Group";
import Settings from "../pages/Merchant/Settings";
import QRType from "../pages/Merchant/QrCode/QRType";
import Facebook from "../pages/Merchant/QrCode/Facebook";
import Appointment from "../pages/Merchant/QrCode/Appointment";
import QrFeedback from "../pages/Merchant/QrCode/Feedback";
import QrForms from "../pages/Merchant/QrCode/Forms";
import Location from "../pages/Merchant/QrCode/Location";
import Event from "../pages/Merchant/QrCode/Event";
import QrGamification from "../pages/Merchant/QrCode/Gamification";
import Polling from "../pages/Merchant/QrCode/Polling";
import Scratchcard from "../pages/Merchant/QrCode/ScratchCard";
import Quiz from "../pages/Merchant/QrCode/Quiz";
import QrDesign from "../pages/Merchant/QrCode/QRDesign";
import CreateQr from "../pages/Merchant/QrCode/CreateQrCode";
import GroupView from "../pages/Merchant/QrCode/Group/GroupQRView";
import ForgotPassword from "../pages/Merchant/Forgetpassword/ForgetPassword";
import Store from "../redux/store/Merchant/store";
import AvailablePalns from "../pages/Merchant/Billing/AvailablePlans";
import Warranty from "../pages/Merchant/Statistics/Warranty";
import Landing from "../pages/LandingPages";
import AppointmentModal from "../pages/LandingPages/CTA/Appointment/AppointmentLanding";
import SubmittedPage from "../pages/LandingPages/CTA/Appointment/SubmitedPage";
import GalleryList from "../pages/LandingPages/CTA/Gallery/GalleryList";

function MerchantRoute() {
  return (
    <Provider store={Store}>
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<SignUp />} />
          <Route path="forgetpassword" element={<ForgotPassword />} />
          <Route path="/:id" element={<Landing />} />
          <Route path="/appointments" element={<AppointmentModal />} />
          <Route path="/submitted" element={<SubmittedPage />} />
          <Route path="/gallery-list" element={<GalleryList />} />

          <Route
            path="/"
            exact
            element={<Sidebar data={SidebarDataMerchant} />}
          >
            <Route index path="home" element={<Dashboard />} />
            <Route index path="/" element={<Dashboard />} />

            <Route path="/notificationIcon" element={<NotificationIcon />} />
            <Route path="qrcode" element={<QRcode />} />
            <Route path="qrcode/createqr" element={<CreateQr />} />
            <Route path="qrcode/edit/:id" element={<CreateQr />} />
            <Route path="qrcode/draft/:id" element={<CreateQr />} />

            <Route path="qrcode/staticcode" element={<StaticQRCode />} />
            <Route path="qrcode/dynamiccode" element={<DynamicQRCode />} />
            <Route path="qrcode/favoritecode" element={<FavoriteQRCode />} />
            <Route path="qrcode/archivedcode" element={<ArchivedQRCode />} />
            <Route path="qrcode/individualqr/:id" element={<IndividualQR />} />
            <Route path="qrcode/qranalytics" element={<QRCodeAnalytics />} />
            <Route path="qrcode/groupqrcode" element={<GroupQRCode />} />
            <Route path="qrcode/groupcodeview" element={<GroupView />} />
            <Route path="/template" element={<Template />} />
            <Route path="qrcode/qrtype" element={<QRType />} />
            <Route path="qrcode/facebook" element={<Facebook />} />
            <Route path="qrcode/appointment" element={<Appointment />} />
            <Route path="qrcode/feedback" element={<QrFeedback />} />
            <Route path="qrcode/forms" element={<QrForms />} />
            <Route path="qrcode/location" element={<Location />} />
            <Route path="qrcode/event" element={<Event />} />
            <Route path="qrcode/gamification" element={<QrGamification />} />
            <Route path="qrcode/polling" element={<Polling />} />
            <Route path="qrcode/scratchcard" element={<Scratchcard />} />
            <Route path="qrcode/quiz" element={<Quiz />} />
            <Route path="qrcode/qrdesign" element={<QrDesign />} />

            <Route path="statistics" element={<Statistics />} />
            <Route path="socialmedia" element={<SocialMedia />} />
            <Route path="appointment" element={<Appointments />} />
            <Route path="feedback" element={<Feedback />} />
            <Route path="forms" element={<Forms />} />
            <Route path="gamification" element={<Gamification />} />
            <Route path="warranty" element={<Warranty />}/>
            <Route path="billing" element={<Billing />} />
            <Route path="settings" element={<Settings />} />
            <Route path="availablePlans" element={<AvailablePalns />}/>

           
          </Route>
        </Routes>
        {/* Toaster container to display notifications */}
      </BrowserRouter>
    </Provider>
  );
}

export default MerchantRoute;
