import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Dot,
} from "recharts";
import api from "../../../Service/Axios";
import { dashboard } from "../../../Service/ApiUrls";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#d235c3",
          padding: "10px 15px",
          borderRadius: "20px",
          color: "#fff",
        }}
      >
        <p className="count" style={{ margin: 0 }}>{`${payload[0].value}K`}</p>
      </div>
    );
  }

  return null;
};

const CustomDot = (props) => {
  const { cx, cy } = props;
  return (
    <Dot
      cx={cx}
      cy={cy}
      r={5}
      fill="#d235c3"
      stroke="#fff"
      strokeWidth={2}
    />
  );
};

const OverallCustomerRateChart = () => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await api.get(dashboard);
        if (response.data.status) {
          const data = response.data.data.overall_customer_rate;

          const formattedData = data.map((item) => ({
            name: `Week ${item.week_of_month}`,
            uv: item.count,
          }));

          setChartData(formattedData);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <LineChart
      width={700}
      height={400}
      data={chartData}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="2 2" stroke="#e0e0e0" />
      <XAxis dataKey="name" tick={{ fill: "#9e9e9e" }} />
      <YAxis tick={{ fill: "#9e9e9e" }} />
      <Tooltip content={<CustomTooltip />} />
      <Line
        type="monotone"
        dataKey="uv"
        stroke="#d235c3"
        strokeWidth={3}
        dot={<CustomDot />}
      />
    </LineChart>
  );
};

export default OverallCustomerRateChart;
