import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import vector from "../../../../assets/image/template/Vector.png";
import axios from "axios";
import api from "../../../../Service/Axios";

const GalleryLanding = ({ id, setSwap }) => {
    const navigate = useNavigate(); // Get the navigate function
    const [galleryData, setGalleryData] = useState(null); // State to store gallery data

    const handleNavigate = () => {
        navigate("/gallery-list"); // Navigate to the desired route
    };

    useEffect(() => {
        const fetchGalleryData = async () => {
            try {
                const response = await api.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                setGalleryData(response.data); // Store the fetched data in state
            } catch (error) {
                console.error("Error fetching gallery data:", error);
            }
        };

        if (id) {
            fetchGalleryData(); // Fetch gallery data when id is available
        }
    }, [id]); // Dependency array ensures fetch happens when id changes

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
        },
        image: {
            width: "20px",
            marginLeft: "10px",
        },
    };

    return (
        <div>
            <button style={styles.button} onClick={() => setSwap(true)}>
                Go To Gallery <img src={vector} style={styles.image} alt="Go to gallery" />
            </button>

            
        </div>
    );
};

export default GalleryLanding;
