import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import api from "../../../../Service/Axios";
import { qr_code_list } from "../../../../Service/ApiUrls";

function CreateGroupModal({ show, handleClose }) {
    const [groupName, setGroupName] = useState("");
    const [description, setDescription] = useState("");
    const [selectedQRCode, setSelectedQRCode] = useState("");
    const [qrCodes, setQrCodes] = useState([]); // For storing QR codes list

    // Fetch QR codes from the API
    useEffect(() => {
        if (show) { // Fetch data only when the modal is opened
            const fetchQrCodes = async () => {
                try {
                    const response = await api.get(qr_code_list);
                    const result = response.data;
                    console.log("loc",response)

                    if (result.status && result.data?.data) {
                        // Transform the data if necessary
                        const transformedData = result.data.data.map((item) => ({
                            id: item.id,
                            name: item.qrcode_name,
                        }));
                        setQrCodes(transformedData);
                    } else {
                        console.error("Failed to fetch QR Code data:", result.message);
                    }
                } catch (error) {
                    console.error("Error fetching QR Code data:", error);
                }
            };

            fetchQrCodes();
        }
    }, [show]);

    // Handle form submission
    const handleCreateGroup = () => {
        const groupData = {
            groupName,
            description,
            selectedQRCode,
        };

        console.log("Group Data:", groupData); // Replace with API call to save the group
        handleClose(); // Close the modal after submission
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Create Group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="formGroupName">
                        <Form.Label>Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter group name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formDescription" className="mt-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group controlId="formQRCode" className="mt-3">
                        <Form.Label>Select QR Code</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedQRCode}
                            onChange={(e) => setSelectedQRCode(e.target.value)}
                        >
                            <option value="">Select QR Code</option>
                            {qrCodes.map((qrCode) => (
                                <option key={qrCode.id} value={qrCode.id}>
                                    {qrCode.name} 
                                    {/* (ID: {qrCode.id}) */}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button> */}
                <Button className="btn-modal" variant="primary" onClick={handleCreateGroup}>
                    Create Group
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default CreateGroupModal;
