import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import createIcon from "../../../../assets/image/Add.svg";
import api from "../../../../Service/Axios";
import { role_search } from "../../../../Service/ApiUrls";
import RolesTable from "./RolesTable";
import CreateRole from "./CreateRole";

function PermissionManager() {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState([]);
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(0);

  useEffect(() => {
    // Fetch status options from roles API
    const fetchStatusOptions = async () => {
      try {
        const response = await api.post(role_search);
        const roles = response.data?.data?.roles || [];
        const uniqueStatuses = Array.from(new Set(roles.map(role => role.active_status)))
          .map(status => ({
            label: status === 1 ? "Active" : "Inactive",
            value: status
          }));
        setStatus([{ label: "All", value: 0 }, ...uniqueStatuses]);
      } catch (error) {
        console.error("Error fetching status options:", error);
      }
    };

    fetchStatusOptions();
  }, []);

  const handleShow = () => setShow(true);

  const handleSearchChange = async (e) => {
    setSearchTerm(e.target.value);
    fetchRoles();
  };

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
    fetchRoles();
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await api.post(role_search, {
        searchTerm,
        active_status: selectedStatus,
      });
      setUserData(response.data?.data?.roles || []);
    } catch (error) {
      console.error("Error searching roles:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Row>
        <Col md={6} lg={4}>
          <SearchBox key_name="search" onhandlechange={handleSearchChange} />
        </Col>
        <Col md={12} lg={8}>
          <div className="d-flex justify-content-between">
            <Dropdown 
              list={status} 
              onChange={handleStatusChange} 
              value={selectedStatus}
            />
            <div className="create" onClick={handleShow}>
              <img src={createIcon} alt="" />
              <p className="mt-3">
                <b>Add Role</b>
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <RolesTable userData={userData} loading={loading} searchTerm={searchTerm} />
      <CreateRole show={show} setShow={setShow} />
    </div>
  );
}

export default PermissionManager;
