import React, { useState, useEffect } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

const Location = ({ handleInputChange, formData }) => {
  const [locations, setLocations] = useState([{ address: "" }]);

  const handleChange = (index, field, value) => {
    const updatedLocations = [...locations];
    updatedLocations[index][field] = value;
    setLocations(updatedLocations);
  };

  const addLocation = () => {
    setLocations([...locations, { address: "" }]);
  };

  const deleteLocation = (index) => {
    const updatedLocations = locations.filter((_, i) => i !== index);
    setLocations(updatedLocations);
  };

  const normalizeLocations = (data) => {
    if (!data) return [{ address: "" }];
    if (Array.isArray(data)) return data.map((loc) => ({ address: loc.address }));
    if (typeof data === "object" && data.address) return [{ address: data.address }];
    return [{ address: "" }];
  };

  useEffect(() => {
    // Normalize the data from formData to ensure locations state is always an array
    if (formData?.location_address) {
      setLocations(normalizeLocations(formData.location_address));
    }
  }, [formData?.location_address]);

  useEffect(() => {
    // Sync locations state back to parent when updated
    if (locations.every((location) => location.address)) {
      const event = {
        target: {
          name: "location_address",
          value: locations,
        },
      };
      handleInputChange(event);
    }
  }, [locations]);

  return (
    <div
      className="border-container"
      style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}
    >
      <Form.Label className="form-label-h">Location Details</Form.Label>
      {locations.map((location, index) => (
        <div key={index} className="mb-4">
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter Google Maps Link"
                value={location.address}
                name="address"
                onChange={(e) => handleChange(index, "address", e.target.value)}
              />
            </Col>
            {/* <Col md={4} className="mb-3">
              <Button
                variant="primary"
                onClick={addLocation}
                size="sm"
                className="add"
              >
                Add Location
              </Button>
              {locations.length > 1 && (
                <Button
                  variant="danger"
                  onClick={() => deleteLocation(index)}
                  size="sm"
                  className="ms-2"
                >
                  Remove
                </Button>
              )}
            </Col> */}
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Control
                type="text"
                placeholder="Enter CTA Name"
                value={formData.cta_name}
                name="cta_name"
                onChange={handleInputChange}
              />
            </Col>
            </Row>
        </div>
      ))}

      {/* Display Locations in a Card */}
      {locations.every((location) => location.address) && (
        <Card className="mt-4">
          <Card.Header>Added Locations</Card.Header>
          <Card.Body>
            {locations.map((location, index) => (
              <div key={index} className="mb-3">
                <Row className="align-items-center">
                  <Col md={6}>
                    <strong>Google Maps Link:</strong> {location.address}
                  </Col>
                </Row>
              </div>
            ))}
          </Card.Body>
        </Card>
      )}
    </div>
  );
};

export default Location;
