import React, { useState, useEffect } from "react";
import axios from "axios";
import vector from "../../../../assets/image/template/Vector.png"

const FeedbacksLanding = ({id}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        full_name: "",
        email_id: "",
        feedback: "",
    });
    const [responseMessage, setResponseMessage] = useState("");

    const toggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    useEffect(() => {
        if (id) {
            // Fetch impression count when the component mounts
            const fetchImpressionCount = async () => {
                try {
                    const response = await axios.get(`https://web.linkoo.io:8001/api/poster/impression/${id}`);
                    if (response?.data?.data?.count) {
                        console.log("Impression Count:", response.data.data.count);
                    } else {
                        console.log("No impression count available for the provided ID.");
                    }
                } catch (error) {
                    console.error("Error fetching impression count:", error);
                }
            };

            fetchImpressionCount();
        }
    }, [id]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                qrcode_id: 30, // Static value as per your requirement
                full_name: formData.full_name,
                email_id: formData.email_id,
                feedback: formData.feedback,
            };

            const response = await axios.post("https://web.linkoo.io:8001/api/poster/submit_feedback", payload, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({...formData, qrcode_id: id}),
            });
    
            
            if (response.data.status) {
                setResponseMessage(response.data.message);
                console.log("API Response:", response.data);
            } else {
                setResponseMessage("Failed to submit feedback");
            }
        } catch (error) {
            console.error("Error submitting feedback:", error);
            setResponseMessage("An error occurred while submitting feedback");
        } finally {
            toggleModal();
        }
    };

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer"
        },
        modalOverlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        modalContent: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            width: "400px",
            maxHeight: "80vh",
            overflowY: "auto",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "232px",
        },
        modalHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
        },
        closeButton: {
            backgroundColor: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
        },
        formGroup: {
            marginBottom: "15px",
        },
        label: {
            display: "block",
            marginBottom: "5px",
            fontWeight: "bold",
            color: "black",
            textAlign: "left",
        },
        input: {
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
        },
        submitButton: {
            padding: "10px 20px",
            backgroundColor: "rgb(220 55 204)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
            width: "100%",
        },
    };

    return (
        <div>
            <button style={styles.button} onClick={toggleModal}>
                Give Your Feedback <img src={vector}/>
            </button>

            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={{ color: "black" }}>Feedback</h2>
                            <button style={styles.closeButton} onClick={toggleModal}>
                                ×
                            </button>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Full Name</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="full_name"
                                    value={formData.full_name}
                                    onChange={handleChange}
                                    placeholder="Enter your name"
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email ID</label>
                                <input
                                    style={styles.input}
                                    type="email"
                                    name="email_id"
                                    value={formData.email_id}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Feedback</label>
                                <textarea
                                    style={styles.input}
                                    name="feedback"
                                    value={formData.feedback}
                                    onChange={handleChange}
                                    placeholder="Enter your feedback"
                                    required
                                />
                            </div>
                            <button style={styles.submitButton} type="submit">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            )}
            {responseMessage && <p>{responseMessage}</p>}
        </div>
    );
};

export default FeedbacksLanding;
