import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import frame from "../../../../assets/image/template/img.png"
import vector from "../../../../assets/image/template/Vector.png"

const WarrantyModal = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email_id: "",
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // For loading state
    const navigate = useNavigate();

    const toggleModal = () => {
        setIsModalOpen((prevState) => !prevState);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await axios.post("https://web.linkoo.io:8001/api/poster/activate_warrenty", formData);

            if (response.status !== 200) {
                throw new Error(`Server Error: ${response.statusText}`);
            }

            const responseData = response.data;
            console.log("API Response:", responseData);

            toggleModal();
            navigate("/warranty-submitted", { state: { formData: responseData } });
        } catch (error) {
            console.error("Error registering warranty:", error);
            alert(`Failed to register warranty. Error: ${error.message}`);
        } finally {
            setIsSubmitting(false);
        }
    };

    const styles = {
        button: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "#fff",
            border: "none",
            borderRadius: "35px",
            cursor: "pointer",
        },
        modalOverlay: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        modalContent: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            width: "400px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            marginTop: "128px",
            maxHeight: "70vh",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
        },
        modalHeader: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "15px",
        },
        closeButton: {
            backgroundColor: "transparent",
            border: "none",
            fontSize: "20px",
            cursor: "pointer",
        },
        formGroup: {
            marginBottom: "15px",
        },
        label: {
            display: "block",
            marginBottom: "5px",
            color: "black",
            textAlign: "justify"

        },
        input: {
            width: "100%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
        },
        submitButton: {
            padding: "10px 20px",
            backgroundColor: "rgb(212 54 197)",
            color: "rgb(255, 255, 255)",
            border: "none",
            borderRadius: "28px",
            cursor: "pointer",
            width: "100%",
            height: "40px",
        },
        image: {
            width: "100%",
            marginBottom: "10px",
        },
        productName: {
            fontWeight: "bold",
            marginBottom: "5px",
        },
        description: {
            marginBottom: "15px",
            color: "black",
            textAlign: "justify"
        },
    };

    return (
        <div>
            <button style={styles.button} onClick={toggleModal}>
                Register Warranty <img src={vector}/>
            </button>

            {isModalOpen && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modalContent}>
                        <div style={styles.modalHeader}>
                            <h2 style={{ color: "black" }}>Register Warranty</h2>
                            <button style={styles.closeButton} onClick={toggleModal}>
                                ×
                            </button>
                        </div>
                        <img src={frame} alt="Sample Product" style={styles.image} />
                        <div style={styles.productName}>Product Name</div>
                        <div style={styles.description}>This is a sample product description providing details about the product's features and warranty coverage.</div>
                        <form onSubmit={handleSubmit}>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Name</label>
                                <input
                                    style={styles.input}
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    placeholder="Enter Your Name"
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div style={styles.formGroup}>
                                <label style={styles.label}>Email</label>
                                <input
                                    style={styles.input}
                                    type="email"
                                    name="email_id"
                                    placeholder="Enter Email Id"
                                    value={formData.email_id}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button
                                style={styles.submitButton}
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? "Submitting..." : "Submit"}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default WarrantyModal;
