import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify styles
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import api from "../../../../Service/Axios";
import { location_edit } from "../../../../Service/ApiUrls"; // Import API endpoint

const CreateLocation = ({ show, setShow, location, onUpdate }) => {
  const [locationName, setLocationName] = useState("");
  const [rememberMe, setRememberMe] = useState(false); // State for checkbox
  const [loading, setLoading] = useState(false); // State for loader

  useEffect(() => {
    if (location) {
      setLocationName(location.location_name || ""); // Prefill location name when editing
    } else {
      setLocationName(""); // Clear input field for new location
    }
  }, [location]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSubmit = async () => {
    // Validate input
    if (!locationName.trim()) {
      toast.error("Please enter a location name");
      return; // Stop further execution if input is invalid
    }

    setLoading(true); // Show loader

    try {
      const payload = { location_name: locationName };
      const response = location
        ? await api.put(`${location_edit}/${location.id}`, payload)
        : await api.post(location_edit, payload);

      if (response.data.status) {
        // Success case
        toast.success(response.data.message);
        onUpdate(response.data.data); // Notify parent to update the list
        handleClose(); // Close modal
      } else {
        // Error case handled by API response
        toast.error(response.data.message || "Failed to save location");
      }
    } catch (error) {
      // Error case for network or unexpected issues
      // toast.error("An error occurred while saving the location");
    } finally {
      setLoading(false); // Hide loader after the operation is complete
    }
  };

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
      <Modal className="modal-main" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {location ? "Edit Location" : "Add New Location"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Location Name</label>
          <InputBox
            type="text"
            placeholder="Enter Location Name"
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
          />
          {/* Checkbox for Remember Me */}
          <div className="d-flex align-items-center mt-3">
            <input
              type="checkbox"
              id="rememberMe"
              checked={rememberMe}
              onChange={handleCheckboxChange}
              className="me-2"
            />
            <label htmlFor="rememberMe" className="color-black mb-0">
              Do you want to set this location as Primary
            </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleSubmit} className="location-btn" disabled={loading}>
            {loading ? "Saving..." : location ? "Update" : "Create"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateLocation;
