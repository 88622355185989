import React from "react";
import Table from "react-bootstrap/Table";
import CircularProgress from '@mui/material/CircularProgress'; // For the loader


export default function AllList({ feedbacks, loading }) {
  return (
    <div className="custom-table mt-3">
      <Table>
        <thead>
          <tr className="f-14 fw-500 tableHead">
            <th>User Name</th>
            <th>Email Id</th>
            <th>Feedback Message</th>
            <th>Code Type</th>
            <th>Code Name</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="7" className="text-center">
                <CircularProgress
                  sx={{
                    color: "pink", // Set the primary loader color to pink
                    animationDuration: "550ms", // Optional: customize the animation speed
                  }}
                  thickness={4} // Optional: make the loader thicker
                  size={50} // Optional: adjust size of the loader
                />
              </td>
            </tr>
          ) : (
            feedbacks.map((value, key) => (
              <tr key={key}>
                <td className="f-16 fw-500 tableText">{value.full_name}</td>
                <td className="f-16 fw-400 tableText">{value.email_id}</td>
                <td className="f-16 fw-400 tableText">{value.feedback}</td>
                <td className="f-16 fw-400 tableText">{value.qrcode_type}</td>
                <td className="f-16 fw-400 tableText">{value.qrcode_name}</td>
                <td className="f-16 fw-400 tableText">
                  {value.active_status === 1 ? "Active" : "Inactive"}
                </td>
              </tr>
            ))
          
          )}
        </tbody>
      </Table>
    </div>
  );
}
