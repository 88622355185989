import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

function ReLineChart({ chartData }) {
  return (
    <div>
      <ResponsiveContainer width="100%" height={260}>
        <AreaChart
          data={chartData}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="created_at" />
          <YAxis />
          <Tooltip />
          <Area type="monotone" dataKey="clicks" stroke="#b47818" fill="#ffebcc" />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default ReLineChart;
