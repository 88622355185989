import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import { toast } from "react-toastify"; // Toaster library
import "react-toastify/dist/ReactToastify.css"; // Toaster styles
import leftarrow from "../../../../assets/image/qrcode/arrow-left.svg";
import Details from "./Details";
import Template from "./Template";
import SubHead from "../../../../Component/Merchant/SubHead";
import Content from "./Content";
import Design from "./Design";
import Steps from "./Steps";
import api from "../../../../Service/Axios"; // Replace with your API service path

import {
  getPosters,
  getQrCodeDetailsByID,
  qr_code_detail,
  qrcode_edit,
} from "../../../../Service/ApiUrls"; // Replace with your API endpoints
import useHandlechanges from "../../../../Hooks/useHandlechanges";
import TemplateQr from "./Template";
import TemplateDetailQr from "./DetailsPreview";
import TemplateDesignQr from "./DesignPreview";
import DesignPreview from "./DesignPreview";

function CreateQr() {
  const { id } = useParams();
  const location = useLocation();
  console.log("path Name", location.pathname.split("/")[2]);
  const { formData, setFormData, handleChange } = useHandlechanges({
    path: "templates",
  });
  console.log("formData", formData);
  const [activeId, setActiveId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [qrId, setQrId] = useState();
  const [loading, setLoading] = useState(false);
  const [ctaOptions, setCtaOptions] = useState([]);
  const [selectedPoster, setSelectedPoster] = useState();
  const [posters, setPosters] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const res = await api.get(getPosters);
        setPosters(res?.data?.data?.posters);
        // toast.success("Posters loaded successfully!");
      } catch (err) {
        toast.error("Error fetching posters.");
        console.error("Error fetching posters:", err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  console.log("formData s", formData);

  const isFormDisabled =
    (formData?.qrcode_type === "static" || "dynamic") &&
    location.pathname.split("/")[2] === "edit";
  console.log("isDesiabled", isFormDisabled);
  const isVideoUrlDisabled =
    (formData.qrcode_type === "static" ? true : false) &&
    location.pathname.split("/")[2] === "edit";

  // Fetch QR Code details by ID
  useEffect(() => {
    if (id) {
      setLoading(true); // Start loading
      api
        .get(`${qrcode_edit}/${id}`)
        .then((response) => {
          if (response.data && response.data.data) {
            const qrCodeData = response.data.data.qr_details;
            setFormData(qrCodeData);
            setQrId(qrCodeData.id);
            setLoading(false); // Stop loading
            setSelectedPoster(qrCodeData)
            console.log("selecte", qrCodeData)
          }
        })
        .catch((error) => {
          toast.error("Failed to fetch QR Code details.");
          setLoading(false); // Stop loading
          console.error("Error fetching QR code details:", error);
        });
    }
  }, [id]);




  const handleSubmit = async () => {
    const data = {
      video_url: formData.video_url,
      upload_type: !formData.video_url.startsWith("http") ? "upload" : "url",
      location_id: formData.location_id || 1,
      lat_long: formData.lat_long,
      qrcode_name: formData.qrcode_name,
      qrcode_type: formData.qrcode_type,
      video_mode: formData.video_mode,
      language: formData.language,
      header_content: formData.header_content,
      cta_name: formData.cta_name,
    };
    console.log("upload", data);

    if (formData.id) {
      data.qrcode_id = formData.id;
    }

    setLoading(true); // Start loading
    try {
      const response = await api.post(qr_code_detail, data);
      setQrId(response?.data?.data?.qr_details?.id);
      toast.success("QR Code details submitted successfully!");
      console.log(
        "QR Code Details Submitted:",
        response?.data?.data?.qr_details?.id,
        qrId
      );
      setActiveId(2);
    } catch (error) {
      toast.error("Error submitting QR Code details.");
      console.error("Error submitting QR code details:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleBack = () => {
    setLoading(true); // Start loading
    setTimeout(() => {
      setLoading(false); // Stop loading
    }, 500); // Simulate a short delay for navigation
  };

  const isValidURL = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // Protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*).)+[a-z]{2,}|" + // Domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // Port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // Query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!urlPattern.test(url);
  };

  // const isValidLatLong = (latLong) => {
  //   const latLongPattern = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d)|(\d{1,2}))(\.\d+)?$/;
  //   return latLongPattern.test(latLong);
  // };

  return (
    <>
      <SubHead
        head="QR code"
        first="Home"
        second="QR Code"
        third="All Codes"
        fourth="Create QR Code"
      />
      <br />
      <Row>
        <Col>
          <div className="left-arrow">
            <Link className="link" to="/qrcode" onClick={handleBack}>
              <h3 className="color-black DMSans-Regular f-18 fw-400">
                <img src={leftarrow} alt="leftarrow" />
                Create New QR Code
              </h3>
            </Link>
          </div>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <button className="btn-download bgcolor-cerise" style={{border: "none",padding: "12px"}}>
              <h1 className="DMSans-Regular f-18 fw-600 color-white">
                Watch Demo
              </h1>
            </button>
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col md={12} lg={9} className="form-container-de">
          <div className="box_billing">
            <Steps activeId={activeId} />
            {activeId === 1 && (
              <Details
                setActiveId={setActiveId}
                formData={formData}
                handleInputChange={handleChange}
                onSubmit={handleSubmit}
                isFormDisabled={isFormDisabled}
                isVideoUrlDisabled={isVideoUrlDisabled}
              />
            )}
            {activeId === 2 && (
              <Content
                id={id || qrId}
                setActiveId={setActiveId}
                formData={formData}
                handleInputChange={handleChange}
                isFormDisabled={isFormDisabled}
                isVideoUrlDisabled={isVideoUrlDisabled}
              />
            )}
            {activeId === 3 && (
              <Design
                id={id || qrId}
                setActiveId={setActiveId}
                formData={formData}
                handleInputChange={handleChange}
                setSelectedPoster={setSelectedPoster}
                selectedPoster={selectedPoster}
                isFormDisabled={isFormDisabled}
                isVideoUrlDisabled={isVideoUrlDisabled}
                posters={posters}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        </Col>
        <Col
          md={4}
          lg={3}
          className="d-flex justify-content-end preview-container"
        >
          {/* <Template /> */}
          {activeId === 1 && <TemplateDetailQr />}
          {activeId === 2 && formData && (
            <TemplateQr
              templateId={formData.template_url} // Pass the template URL
              headerContent={formData.header_content}
              description={formData.description}
              ctaOption={formData.cta_category_id}
              ctaOptions={ctaOptions}
              selectedSocialMedia={formData.socialName} // Pass the selected social media to preview
            />
          )}

          {activeId === 3 && selectedPoster && (
            <DesignPreview
              poster={selectedPoster}
              BASE_URL="https://linkoo.s3.amazonaws.com/"
              list={posters}
            />
          )}
        </Col>
      </Row>
      {loading && <p className="loading-spinner">Loading...</p>}
    </>
  );
}

export default CreateQr;
