import React, { useState, useEffect } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import useHandlechanges from "../../../../../Hooks/useHandlechanges";

const GalleryUpload = ({ handleInputChange, data }) => {
  const { formData, handleChange, setFormData } = useHandlechanges({
    path: "templates",
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const processFiles = (files) => {
    const validFiles = Array.from(files).filter(
      (file) =>
        file.size <= 25 * 1024 * 1024 && // Check file size (<= 25 MB)
        /\.(jpg|jpeg|png)$/i.test(file.name) // Only allow image files
    );

    if (validFiles.length !== files.length) {
      alert("Some files were invalid or exceeded 25MB.");
    }

    const galleryImages = validFiles.map((file) => ({
      image: URL.createObjectURL(file),
    }));

    setUploadedFiles((prev) => [...prev, ...galleryImages]);
  };

  useEffect(() => {
      const documentEvent = {
        target: {
          name: "add_gallery_images",
          value: [formData],
        },
      };
      if (Object.keys(formData).length > 0) handleInputChange(documentEvent);
    
  }, [formData]);

    useEffect(() => {
      if (data?.add_gallery_images) {
        const gallerData = data.add_gallery_images;
        setFormData(gallerData);
        setUploadedFiles(gallerData?.add_gallery_images || []);
      }
    }, []);

    console.log("gallery", data);


  // useEffect(() => {
  //   if (uploadedFiles.length > 0) {
  //     // Update gallery_images in formData
  //     const updatedGalleryImages = uploadedFiles.map((file) => ({
  //       image: file.image,
  //     }));

  //     handleInputChange({
  //       target: {
  //         name: "gallery_images",
  //         value: updatedGalleryImages,
  //       },
  //     });
  //   }
  // }, [uploadedFiles]);

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);
    const files = e.dataTransfer.files;
    processFiles(files);
  };

  return (
    <div
      className="border-container"
      style={{
        border: "1px solid #ccc",
        padding: "20px",
        marginTop: "20px",
      }}
    >
      <Form.Label className="form-label-h">Gallery Details</Form.Label>

      {/* CTA Name */}
      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter CTA Name"
            name="cta_name"
            value={formData.cta_name}
            onChange={handleChange}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Enter Folder Name"
            name="folder_name"
            value={formData.folder_name || ""}
            onChange={handleChange}
          />
        </Col>
      </Row>

      {/* Upload Images */}
      <Row className="mb-3">
        <Col md={12}>
          <div
            style={{
              border: isDragOver ? "2px dashed #007bff" : "2px dashed #ccc",
              padding: "20px",
              textAlign: "center",
              backgroundColor: isDragOver ? "#e9f7ff" : "#fff",
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <p>Drag and drop images here or click to browse</p>
            <input
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              style={{ display: "none" }}
              id="upload-input"
              name="gallery_images"
              onChange={(e) => handleChange(e, "image")}
              
              
            />
            <label
              htmlFor="upload-input"
              style={{
                cursor: "pointer",
                backgroundColor: "#f7f7f7",
                padding: "10px 20px",
                borderRadius: "5px",
                display: "inline-block",
              }}
            >
              Browse Files
            </label>
            <p className="mt-3">
              Supported formats: .jpg, .jpeg, .png | Max size: 25MB
            </p>
          </div>
        </Col>
      </Row>

      {/* Uploaded Images */}
      <Row className="mb-3">
        <Col md={12}>
          {uploadedFiles.length > 0 && (
            <Card>
              <Card.Header>Uploaded Images</Card.Header>
              <Card.Body>
                <ul>
                  {uploadedFiles.map((file, index) => (
                    <li key={index}>
                      <img
                        src={file.image}
                        alt={`Uploaded ${index + 1}`}
                        style={{ width: "50px", marginRight: "10px" }}
                      />
                      <span>{file.image}</span>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {/* End Screen Message */}
      <Row className="mb-3">
        <Col md={12}>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter End Screen Message"
            name="end_screen_message"
            value={formData.end_screen_message}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GalleryUpload;
