import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Actions from "./Actions"; // Import the Action component
import api from "../../../../Service/Axios"; // Assuming you have Axios instance setup
import { qr_code_list, qr_code_delete } from "../../../../Service/ApiUrls"; // Your API URLs
import { toast } from "react-toastify"; // Import toast
import Spinner from "react-bootstrap/Spinner"; // Import Spinner for loader
export default function AllList({ searchTerm, activeStatus, codeType, ctaType }) {
  // const { setActiveId, formData, handleInputChange, setQrId, id, onSubmit } =
  // props;
  const [qrCodeData, setQrCodeData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [locationCache, setLocationCache] = useState({}); // Cache for location names

  const [loading, setLoading] = useState(true); // State for loader

   // Fetch QR Code list
   useEffect(() => {
    const fetchQrCodeData = async () => {
      setLoading(true); // Show loader when fetching data
      try {
        const response = await api.get(qr_code_list);
        const result = response.data;

        if (result.status && result.data?.data) {
          const transformedData = result.data.data.map((item) => ({
            id: item.id,
            codeName: item.qrcode_name,
            codeUrl: item.qrcode_value,
            codeType: item.qrcode_type.toString(),
            description: item.description,
            location: item.lat_long,
            location_name: item.location_name,
            ctaType: item.cta_category_name,
            ctaTypeId: item.cta_category_id,
            status: item.create_status === "completed" ? "Active" : "Inactive",
          }));
          setQrCodeData(transformedData);
        } else {
          console.error("Failed to fetch QR Code data:", result.message);
        }
      } catch (error) {
        console.error("Error fetching QR Code data:", error);
      } finally {
        setLoading(false); // Hide loader after data is fetched
      }
    };

    fetchQrCodeData();
  }, []);



  // Filter data
  useEffect(() => {
    const filterData = () => {
      let filtered = qrCodeData;
      filtered.sort((a, b) => a.id - b.id);

      // Search Term Filter
      if (searchTerm) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        filtered = filtered.filter(
          (item) =>
            item.codeName.toLowerCase().includes(lowerSearchTerm) ||
            item.codeType.toLowerCase().includes(lowerSearchTerm) ||
            item.ctaType.toLowerCase().includes(lowerSearchTerm)
        );
      }

      // Active Status Filter
      if (activeStatus !== 0) {
        const statusFilter = activeStatus === 1 ? "Active" : "Inactive";
        filtered = filtered.filter((item) => item.status === statusFilter);
      }

      // Code Type Filter
      if (codeType !== 0) {
        const codeTypeFilter = codeType === 1 ? "Static" : "Dynamic";
        filtered = filtered.filter((item) => item.codeType.toLowerCase() === codeTypeFilter.toLowerCase());
      }

      // CTA Type Filter
      if (ctaType !== 0) {
        filtered = filtered.filter((item) => item.ctaTypeId === ctaType); // Ensure `ctaTypeId` is used for comparison
      }

      setFilteredData(filtered);
    };

    filterData();
  }, [qrCodeData, searchTerm, activeStatus, codeType, ctaType]);

  const handleDelete = async (id) => {
    try {
      const response = await api.get(`${qr_code_delete}/${id}`);
      if (response.status === 200) {
        // Update the data after successful deletion
        setQrCodeData((prevData) => prevData.filter((item) => item.id !== id));
        toast.success("QR Code deleted successfully!", {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Failed to delete QR Code. Please try again.", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("An error occurred while deleting the QR Code.", {
        position: "top-right",
        autoClose: 3000,
      });
      console.error("Delete Error:", error);
    }
  };

  return (
    <div className="custom-table mt-3">
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <Table>
          <thead>
            <tr className="f-14 fw-500 tableHead">
              <th>ID</th>
              <th>Code Name</th>
              <th>Code Url</th>
              <th>Code Type</th>
              <th>Description</th>
              <th>Location</th>
              <th>CTA Type</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((value, key) => (
              <tr key={key}>
                <td className="f-16 fw-500 tableText">{value.id}</td>
                <td className="f-16 fw-500 tableText">{value.codeName}</td>
                <td className="f-16 fw-400 tableText">{value.codeUrl}</td>
                <td className="f-16 fw-400 tableText">{value.codeType}</td>
                <td className="f-16 fw-400 tableText">{value.description}</td>
                <td className="f-16 fw-400 tableText">{value.location || value.location_name}</td>
                <td className="f-16 fw-400 tableText">{value.ctaType}</td>
                <td className="f-16 fw-400 tableText">
                  <small className={value.status === "Active" ? "status-active" : "status-inactive"}>
                    {value.status}
                  </small>
                </td>
                <td>
                  <div className="mt-3">
                    <Actions rowData={value} handleDelete={handleDelete} />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
}
