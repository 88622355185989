import React, { useState, useEffect } from "react";
import { Col, Row, Button, Form, Card } from "react-bootstrap";
import radio from "../../../../assets/image/qrcode/radio.svg";
import appointment from "../../../../assets/image/qrcode/appointments.svg";
import event from "../../../../assets/image/qrcode/event.svg";
import form from "../../../../assets/image/qrcode/file.svg";
import location from "../../../../assets/image/qrcode/pin.svg";
import feedback from "../../../../assets/image/qrcode/feedback.svg";
import gamification from "../../../../assets/image/qrcode/gamification.svg";
import gallery from "../../../../assets/image/qrcode/gallery.png";
import menu from "../../../../assets/image/qrcode/menu.svg";
import warranty from "../../../../assets/image/qrcode/warranty.svg";
import document from "../../../../assets/image/qrcode/document.svg";
import contact from "../../../../assets/image/qrcode/contact.png";
import link from "../../../../assets/image/qrcode/link.png";
// import api from "../../../../Service/Axios";
import { cta_categories, qr_code_content, templates } from "../../../../Service/ApiUrls";
import up from "../../../../assets/image/qrcode/up.svg";
import InputBox from "../../../../Component/Merchant/Forms/InputBox";
import TextArea from "../../../../Component/Merchant/Forms/TextArea";
import SocialMedia from "./CTA_Options/SocialMedia";
import Appointment from "./CTA_Options/Appointment";
import Links from "./CTA_Options/Links";
import Feedback from "./CTA_Options/Feedback";
import Contact from "./CTA_Options/Contact";
import Location from "./CTA_Options/Location";
import Event from "./CTA_Options/Event";
import { toast } from "react-toastify";
import DocumentUpload from "./CTA_Options/Document";
import api from "../../../../Service/Axios";
import ProductUpload from "./CTA_Options/Warranty";
import GalleryUpload from "./CTA_Options/Gallery";
import TemplateQr from "./Template";
import "../../../../assets/Css/style.css"
import down from "../../../../assets/image/template/down-arrow.png"
import Warranty from "./CTA_Options/Warranty";

// import { cta_categories } from "../../../../service/apiUrls";
// import DocumentUpload from "./CTA_Options/Document";
// You can set up your icon map based on cta_category_name
const iconMap = {
  "Social Media": radio,
  Appointments: appointment,
  Event: event,
  Forms: form,
  Location: location,
  "Feedback Survey": feedback,
  Gamification: gamification,
  "Menu/Catalog": menu,
  Gallery: gallery,
  "Warranty Module": warranty,
  Documents: document,
  "Contact Info": contact,
  Links: link,
};

function Content(props) {
  const { setActiveId, id, formData, handleInputChange, isDisabled, isFormDisabled, isVideoUrlDisabled } = props;
  console.log("content formData", formData)
  const [ctaOptions, setCtaOptions] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  const [showTemplates, setShowTemplates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({}); // State for validation errors

  useEffect(() => {
    const fetchCtaOptions = async () => {
      try {
        const response = await api.get(cta_categories);
        const categories = response.data?.data?.cta_categories || [];
        setCtaOptions(categories);
      } catch (error) {
        toast.error("Error fetching CTA options");
      }
    };

    fetchCtaOptions();
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const response = await api.get(templates);
        const templatesData = response.data?.data?.template_lists || [];
        setTemplatesList(templatesData);
      } catch (error) {
        toast.error("Error fetching templates");
      }
    };

    fetchTemplates();
  }, []);

  const handleTemplateClick = (templateId, templateUrl) => {
    handleInputChange({ target: { name: 'template_id', value: templateId } });
    handleInputChange({ target: { name: 'template_url', value: templateUrl } });
    console.log('Selected Template:', templateId, 'URL:', templateUrl);
  };

  const handleTemplateToggle = () => {
    setShowTemplates(!showTemplates); // Toggle the visibility of the templates
  };

  const handleCtaSelect = (cta) => {
    let event = { target: { name: "cta_category_id", value: cta } };
    handleInputChange(event);
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return; // Stop if validation fails
    }
    setIsLoading(true); // Show loader
    const data = {
      ...formData,
      template_id: formData.template_id, // Default to template ID 2 if not selected
    };
    try {
      const response = await api.put(`${qr_code_content}${id}`, data);
      toast.success("QR Code Details Submitted Successfully!");
      setActiveId(3);
    } catch (error) {
      toast.error("Error submitting QR Code details.");
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.header_content) {
      newErrors.header_content = "Header Content is required.";
    }
    if (!formData.description) {
      newErrors.description = "Description is required.";
    }
    if (!formData.cta_category_id) {
      newErrors.cta_category_id = "Select any one CTA option.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // const isFormDisabled = formData.qrcode_type === "static";


  return (
    <div
      className="form-container"
      style={{
        maxHeight: "500px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        padding: "15px",
      }}
    >
      <>

        <Row>
          <h3
            className="Inter-Regular f-16 fw-600 cursor-pointer"
            onClick={handleTemplateToggle} // Add toggle onClick
            disabled={isFormDisabled}

          >
            Template design
            <span className="up">
              <img src={showTemplates ? up : down} alt="" /> {/* Change src based on showTemplates */}
            </span>
          </h3>
        </Row>

        {showTemplates && (
          <Row className="template-list mt-3">
            {templatesList.length > 0 ? (
              templatesList.map((template) => (
                <Col
                  md={4} lg={3}
                  className={`template-item mb-4 p-3 border ${formData?.template_id === template.id ? "border-success" : ""}`}
                  key={template.id}
                  onClick={() => handleTemplateClick(template.id, template.template_url)}
                >
                  <img
                    src={`https://linkoo.s3.amazonaws.com/${template.template_url}`}
                    alt={template.template_name}
                    className="img-fluid" // You can adjust this class or add custom styles
                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }} // Adjust size as needed

                  />
                  <br />
                  <h5 className="text-center mt-2" style={{ fontSize: "18px" }}>{template.template_name}</h5> {/* Show template name */}
                </Col>
              ))
            ) : (
              <p>Loading templates...</p>
            )}
          </Row>
        )}


        <Row>

          <Col md={6} lg={6}>
            <InputBox
              type="text"
              label="Header Content"
              placeholder="Sample video Test"
              key_name="header_content"
              {...formData}
              onChange={handleInputChange}
              disabled={isFormDisabled}

            />
            {errors.header_content && <span className="error">{errors.header_content}</span>}

          </Col>
        </Row>
        <br />
        <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row>
        <br />
        <Row>
          <Col md={6} lg={6}>
            <TextArea
              type="text"
              label="Description"
              placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt."
              key_name="description"
              {...formData}
              onChange={handleInputChange}
              disab={isFormDisabled}

            />
            {errors.description && <span className="error">{errors.description}</span>}

          </Col>
        </Row>
        <br />
        <Row className="d-flex justify-content-between align-items-center border-bottom py-3"></Row>
        <br />
        <Row>
          <h3 className="Inter-Regular f-14 fw-400">CTA option</h3>
        </Row>

        {/* Dynamically render CTA options in rows of 5 items */}
        {/* <div className="cta-options-container">
        {ctaOptions.length > 0 ? (
          <div className="mb-3 d-flex flex-wrap gap-3">
            {ctaOptions.map((cta, index) => (
              <div key={index} md={2} className="cta-item" >
                <div
                  className={`cta-item-container cursor tags d-flex justify-content-center align-items-center ${formData?.cta_category_id === cta.id
                    ? "bg-light-green" // Apply light green background to selected item
                    : ""
                    }`}
                  onClick={() => handleCtaSelect(cta.id)} // Set selected CTA on click

                >
                  <img
                    src={iconMap[cta.cta_category_name] || radio}
                    alt={cta.cta_category_name}
                    className="cta-icon me-2"
                  />
                  <h3 className="DMSans-Regular f-16 fw-400 color-emperor mb-0">
                    {cta.cta_category_name}
                  </h3>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>Loading CTA options...</p>
        )}
      </div> */}
        <div className="cta-options-container">
          {ctaOptions.length > 0 ? (
            <div className="mb-3 d-flex flex-wrap gap-3">
              {ctaOptions.map((cta, index) => {
                // Check if the form is disabled and if the current CTA is not the selected one
                const isSelected = formData?.cta_category_id === cta.id;
                const isDisabled = isFormDisabled && !isSelected;

                return (
                  <div key={index} md={2} className="cta-item">
                    <div
                      className={`cta-item-container cursor tags d-flex justify-content-center align-items-center ${isSelected ? "bg-light-green" : ""}`}
                      onClick={() => !isDisabled && handleCtaSelect(cta.id)} // Only allow selection if not disabled
                      style={{ opacity: isDisabled ? 0.5 : 1 }} // Optional: visually indicate disabled state
                    >
                      <img
                        src={iconMap[cta.cta_category_name] || radio}
                        alt={cta.cta_category_name}
                        className="cta-icon me-2"
                      />
                      <h3 className="DMSans-Regular f-16 fw-400 color-emperor mb-0">
                        {cta.cta_category_name}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <p>Loading CTA options...</p>
          )}
        </div>
        {errors.cta_category_id && <span className="error">{errors.cta_category_id}</span>}

        <br />

        {formData?.cta_category_id === 1 && (
          <SocialMedia handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 2 && (
          <Appointment handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 3 && (
          <Links handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 6 && (
          <Event handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 5 && (
          <Location handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 7 && (
          <Feedback handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 8 && (
          <Contact handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 13 && (
          <DocumentUpload handleInputChange={handleInputChange} data={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}

        {formData?.cta_category_id === 14 && (
          // <Warranty handleInputChange={handleInputChange} formData={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />

          <ProductUpload handleInputChange={handleInputChange} data={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled}/>
        )}

        {formData?.cta_category_id === 15 && (
          <GalleryUpload handleInputChange={handleInputChange} data={formData} isFormDisabled={isFormDisabled} isVideoUrlDisabled={isVideoUrlDisabled} />
        )}
        <br />
        <Row className="d-inline-flex">
          <Col className="d-flex justify-content-start">
            <button
              className="btn-download bgcolor-white"
              onClick={() => setActiveId(1)}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Back"}
            </button>
          </Col>
          <Col className="d-flex justify-content-between">
            <button
              className="btn-download bgcolor-cerise"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? "Submitting..." : "Next"}
            </button>
          </Col>
        </Row>

      </>
    </div>
  );
}

export default Content;
