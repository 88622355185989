import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { BsThreeDotsVertical, BsGraphUpArrow } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { MdOutlineColorLens } from "react-icons/md";
import { LiaCopy } from "react-icons/lia";
import { RiDeleteBinLine } from "react-icons/ri";
import { FaRegShareSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Action(props) {
  const { rowData, handleCopy, handleDelete } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const navigate = useNavigate();

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleCopyClick = () => {
    const rowText = `Code Name: ${rowData.codeName}\nCode URL: ${rowData.codeUrl}\nCode Type: ${rowData.codeType}\nDescription: ${rowData.description}\nLocation: ${rowData.location}\nCTA Type: ${rowData.ctaType}\nStatus: ${rowData.status}`;
    navigator.clipboard.writeText(rowText)
      .then(() => {
        alert("Row data copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    handleClose();
  };

  const handleDeleteClick = () => {
    handleDialogOpen();
  };

  const confirmDelete = () => {
    handleDelete(rowData.id);
    handleDialogClose();
  };

  const handleEditClick = () => {
    navigate(`/qrcode/edit/${rowData.id}`);
    handleClose();
  };

  return (
    <div className="option">
      <div><p className="mb-0">Options</p></div>
      <div>
        <BsThreeDotsVertical className="cursor" onClick={handleClick} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem>
            <BsGraphUpArrow />
            <Link to={`/qrcode/individualqr/${rowData.id}`} style={{ textDecoration: "none", color: "black" }}>
              &nbsp;QR Analysis
            </Link>
          </MenuItem>
          <MenuItem>
            <FiEdit3 />
            <Link to={`/qrcode/edit/${rowData.id}`} style={{ textDecoration: "none", color: "black" }}>
              &nbsp;Edit Content
            </Link>
          </MenuItem>
          <MenuItem onClick={handleCopyClick}><LiaCopy />&nbsp;Make a copy</MenuItem>
          <MenuItem onClick={handleDeleteClick}><RiDeleteBinLine />&nbsp;Delete</MenuItem>
          <MenuItem><FaRegShareSquare />&nbsp;Share this QR</MenuItem>
        </Menu>
      </div>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this QR Code?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} style={{color: "rgb(255 255 255)",background: "#d436c5"}} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default React.memo(Action);
