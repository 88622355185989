// InputBoxHorizontal.js
import React from "react";
import { Col, Row } from "react-bootstrap";

function InputBoxHorizontal(props) {
  const { label, key_name, placeholder, type, value, onhandlechange, onChangePassword } = props;

  return (
    <Row className="mt-3">
      <Col lg={4} className="d-flex align-items-center">
        <label className="label">{label}</label>
      </Col>
      <Col lg={8} style={{ position: "relative" }}>
        <input
          type={type}
          name={key_name}
          className="custom-horizontal-input"
          placeholder={placeholder}
          style={{ paddingRight: "60px" }} // Adjust padding to accommodate the button
          value={value} // Controlled value
          onChange={onhandlechange} // Handle input changes

        />
        {/* Conditionally render "Change" button for Current Password */}
        {label === "Current Password" && onChangePassword && (
          <button
            type="button"
            className="change-button"
            style={{
              position: "absolute",
              top: "50%",
              right: "20px",
              transform: "translateY(-50%)",
              padding: "0px 10px",
              color: "#d235c3",
              backgroundColor: "#ffffff",
              border: "none",
              cursor: "pointer",
              fontWeight: "bold",
            }}
            onClick={onChangePassword}
            
          >
            Change
          </button>
        )}
      </Col>
    </Row>
  );
}

export default InputBoxHorizontal;
