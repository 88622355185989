import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";

const Contact = ({ handleInputChange, formData }) => {
  const [contactData, setContactData] = useState({
    company_url: "",
    full_name: "",
    company_name: "",
    email_id: "",
    mobile_number: "",
    address: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (formData?.contact_info?.length > 0) {
      const contactInfo = formData.contact_info[0];
      setContactData({
        company_url: contactInfo?.company_url || "",
        full_name: contactInfo?.full_name || "",
        company_name: contactInfo?.company_name || "",
        email_id: contactInfo?.email_id || "",
        mobile_number: contactInfo?.mobile_number || "",
        address: contactInfo?.address || "",
      });
    }
  }, [formData?.contact_info]);

  useEffect(() => {
    const { company_url, full_name, company_name, email_id, mobile_number, address } = contactData;
    if (company_url && full_name && company_name && email_id && mobile_number && address) {
      const event = {
        target: {
          name: "contact_info",
          value: [contactData],
        },
      };
      handleInputChange(event);
    }
  }, [contactData]);

  return (
    <div className="border-container" style={{ border: "1px solid #ccc", padding: "20px", marginTop: "20px" }}>
      <Row>
        <Form.Label htmlFor="contactDetails">CTA Option</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Company URL"
            value={contactData.company_url}
            name="company_url"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label htmlFor="contactDetails">Full Name</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Full Name"
            value={contactData.full_name}
            name="full_name"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label htmlFor="contactDetails">Company / Organization Name</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Company/Organization"
            value={contactData.company_name}
            name="company_name"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label htmlFor="contactDetails">Email ID</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            type="email"
            placeholder="Enter Email ID"
            value={contactData.email_id}
            name="email_id"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label htmlFor="contactDetails">Mobile Number</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter Mobile Number"
            value={contactData.mobile_number}
            name="mobile_number"
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Form.Label htmlFor="contactDetails">Address</Form.Label>
        <Col md={6} className="mb-3">
          <Form.Control
            as="textarea"
            rows={2}
            placeholder="Enter Address"
            value={contactData.address}
            name="address"
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Contact;
