import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import api from "../../../../Service/Axios";
import { role, role_update } from "../../../../Service/ApiUrls";
import { toast } from "react-toastify"; // Import Toastify

function CreateRole({ show, setShow, editData }) {
  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState({
    fullAccess: false,
    editAccess: false,
    other: false,
    viewOnlyAccess: false,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (editData) {
      setRoleName(editData.role_name || "");
      setPermissions({
        fullAccess: editData.full_access === 1,
        editAccess: editData.edit_access === 1,
        other: editData.other === 1,
        viewOnlyAccess: editData.view_access === 1,
      });
    }
  }, [editData]);

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  const resetForm = () => {
    setRoleName("");
    setPermissions({
      fullAccess: false,
      editAccess: false,
      viewOnlyAccess: false,
    });
    setErrorMessage("");
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setPermissions((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const roleData = {
      role_name: roleName,
      full_access: permissions.fullAccess ? 1 : 0,
      edit_access: permissions.editAccess ? 1 : 0,
      other: permissions.other ? 1 : 0,
      view_access: permissions.viewOnlyAccess ? 1 : 0,
    };

    try {
      let response;
      if (editData) {
        response = await api.put(`${role_update}/${editData.id}`, roleData);
      } else {
        response = await api.post(role, roleData);
      }

      if (response.status === 200) {
        toast.success(editData ? "Role Updated Successfully" : "Role Created Successfully");
        handleClose();
      } else {
        setErrorMessage(response.data.message || "Error processing role");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage(
        error.response?.data?.message || "An unexpected error occurred"
      );
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{editData ? "Edit Role" : "Create Role"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="roleName">
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter role name"
              value={roleName}
              onChange={(e) => setRoleName(e.target.value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Permissions</Form.Label>
            <div className="d-flex justify-content-between">
              <Form.Check
                type="checkbox"
                label="Full Access"
                name="fullAccess"
                checked={permissions.fullAccess}
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                label="Edit Access"
                name="editAccess"
                checked={permissions.editAccess}
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                label="Others"
                name="other"
                checked={permissions.other}
                onChange={handleCheckboxChange}
              />
              <Form.Check
                type="checkbox"
                label="View Only Access"
                name="viewOnlyAccess"
                checked={permissions.viewOnlyAccess}
                onChange={handleCheckboxChange}
              />
            </div>
          </Form.Group>
          <Button variant="primary" type="submit">
            {editData ? "Update" : "Create"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CreateRole;
