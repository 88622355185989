import React, { useEffect, useState } from "react";
// import { Table } from "react-bootstrap";
import bin from "../../../../assets/image/bin.svg";
import edit from "../../../../assets/image/edit.svg";
import api from "../../../../Service/Axios";
import { users, user_edit, delete_user } from "../../../../Service/ApiUrls";
import CreateUser from "./CreateUser";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { Table, Modal, Button } from "react-bootstrap";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
function UserTable() {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await api.get(users);
        setUserData(response.data?.data.users || []);
        setLoading(false);
        // toast.success("Users loaded successfully!");
      } catch (error) {
        console.error("Error fetching users:", error);
        toast.error("Failed to load users. Please try again later.");
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleEdit = async (userId) => {
    try {
      const response = await api.get(`${user_edit}/${userId}`);
      setEditUser(response.data?.data?.user || null);
      setShowModal(true);
      // toast.success("User data fetched successfully for editing!");
    } catch (error) {
      console.error("Error fetching user details:", error);
      toast.error("Failed to fetch user details. Please try again later.");
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await api.get(`${delete_user}/${userId}`);
      if (response.status === 200) {
        setUserData(userData.filter((user) => user.id !== userId));
        toast.success("User deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user. Please try again later.");
    }
  };

  const handleDeleteClick = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await api.get(`${delete_user}/${userIdToDelete}`);
      if (response.status === 200) {
        setUserData(userData.filter((user) => user.id !== userIdToDelete));
        toast.success("User deleted successfully!");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error("Failed to delete user. Please try again later.");
    }
    setShowDeleteDialog(false);
  };


  return (
    <div className="custom-table mt-3">
      <style jsx>{`
        .custom-table {
          padding: 10px;
        }

        th,
        td {
          white-space: nowrap;
          text-align: center;
          padding: 10px;
        }

        img.cursor {
          cursor: pointer;
          width: 20px;
          height: 20px;
        }

        .status-active {
          color: green;
        }

        .status-inactive {
          color: red;
        }

        @media (max-width: 576px) {
          .table-container {
            display: none;
          }
          .card-container {
            display: block;
          }
        }

        @media (min-width: 577px) {
          .table-container {
            display: block;
          }
          .card-container {
            display: none;
          }
        }

        .card {
          background: #fff;
          border: 1px solid #ddd;
          border-radius: 8px;
          margin-bottom: 10px;
          padding: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }

        .card-header {
          font-weight: bold;
          margin-bottom: 5px;
        }

        .card-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        img.cursor {
          width: 20px;
          height: 20px;
        }
      `}</style>

      {/* Table for larger screens */}
      <div className="table-container">
        <Table>
          <thead>
            <tr>
              <th style={{textAlign: "justify"}}>User Name</th>
              <th>Email Id</th>
              <th>Role</th>
              <th>Permissions</th>
              <th>Creation Date</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7" className="text-center">
                  <CircularProgress sx={{ color: "pink" }} thickness={4} size={50} />
                </td>
              </tr>
            ) : (
              userData.map((user) => (
                <tr key={user.id}>
                  <td style={{textAlign: "justify"}}>{user.full_name}</td>
                  <td>{user.email_id}</td>
                  <td>{user.role_name}</td>
                  <td>
                    {user.full_access === 0 &&
                    user.edit_access === 0 &&
                    user.view_access === 0 ? (
                      <div>No Permissions</div>
                    ) : (
                      <div>
                        {user.full_access === 1 && <div>Full Access</div>}
                        {user.edit_access === 1 && <div>Edit Access</div>}
                        {user.view_access === 1 && <div>View Access</div>}
                      </div>
                    )}
                  </td>
                  <td>{new Date(user.created_at).toLocaleDateString()}</td>
                  <td>
                    <small
                      className={
                        user.active_status === 1 ? "status-active" : "status-inactive"
                      }
                    >
                      {user.active_status === 1 ? "Active" : "Inactive"}
                    </small>
                  </td>
                  <td>
                    <img
                      src={edit}
                      alt="Edit"
                      className="mt-3 me-3 cursor"
                      onClick={() => handleEdit(user.id)}
                    />
                    <img
                      src={bin}
                      alt="Delete"
                      className="mt-3 cursor"
                      onClick={() => handleDeleteClick(user.id)}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </div>

      <Dialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="secondary" style={{    border: "none",background: "none",color: "#2559ac"}}>
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error" style={{background: "#d435c5",border: "none"}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cards for smaller screens */}
      <div className="card-container">
        {loading ? (
          <div className="text-center">
            <CircularProgress sx={{ color: "pink" }} thickness={4} size={50} />
          </div>
        ) : (
          userData.map((user) => (
            <div key={user.id} className="card">
              <div className="card-header">{user.full_name}</div>
              <div className="card-content">
                <div>
                  <p>Email: {user.email_id}</p>
                  <p>Role: {user.role_name}</p>
                  <p>
                    Permissions:{" "}
                    {user.full_access === 0 &&
                    user.edit_access === 0 &&
                    user.view_access === 0
                      ? "No Permissions"
                      : [
                          user.full_access === 1 && "Full Access",
                          user.edit_access === 1 && "Edit Access",
                          user.view_access === 1 && "View Access",
                        ]
                          .filter(Boolean)
                          .join(", ")}
                  </p>
                  <p>Created At: {new Date(user.created_at).toLocaleDateString()}</p>
                  <p>
                    Status:{" "}
                    <span
                      className={
                        user.active_status === 1 ? "status-active" : "status-inactive"
                      }
                    >
                      {user.active_status === 1 ? "Active" : "Inactive"}
                    </span>
                  </p>
                   <img
                    src={edit}
                    alt="Edit"
                    className="me-3 cursor"
                    onClick={() => handleEdit(user.id)}
                  />
                  <img
                    src={bin}
                    alt="Delete"
                    className="cursor"
                    onClick={() => handleDelete(user.id)}
                  />
                </div>
                <div>
                 
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      

      {showModal && (
        <CreateUser
          show={showModal}
          setShow={setShowModal}
          editData={editUser}
        />
      )}
    </div>
  );
}

export default UserTable;
