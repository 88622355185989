import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import AllList from "./FeedbackTable";
import { Button } from "@mui/material";
import Dropdown from "../../../../Component/Merchant/Forms/Dropdown";
import SearchBox from "../../../../Component/Merchant/SearchBox";
import api from "../../../../Service/Axios";
import { export_feedback, feedbacks_eve} from "../../../../Service/ApiUrls";

function Feedback() {
  const [searchTerm, setSearchTerm] = useState("");
  const [feedbacks, setFeedbacks] = useState([]);
  const [filteredFeedbacks, setFilteredFeedbacks] = useState([]);
  const [qrCodeNames, setQrCodeNames] = useState([]);
  const [selectedQrCode, setSelectedQrCode] = useState("");
  const [codeTypes, setCodeTypes] = useState([]);
  const [selectedCodeType, setSelectedCodeType] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeedbacks = async () => {
      try {
        const response = await api.post(feedbacks_eve);
        const feedbackData = Array.isArray(response.data?.data?.feed_backs)
          ? response.data.data.feed_backs
          : [];
        setFeedbacks(feedbackData);
        setFilteredFeedbacks(feedbackData);

        const qrCodes = [
          { label: "All", value: "" },
          ...Array.from(
            new Set(feedbackData.map((fb) => fb.qrcode_name))
          ).map((name) => ({ label: name, value: name })),
        ];
        setQrCodeNames(qrCodes);

        const types = [
          { label: "All", value: "" },
          ...Array.from(
            new Set(feedbackData.map((fb) => fb.qrcode_type))
          ).map((type) => ({ label: type, value: type })),
        ];
        setCodeTypes(types);
        setLoading(false);

      } catch (error) {
        console.error("Error fetching feedbacks:", error);
        setLoading(false);

      }
    };

    fetchFeedbacks();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    applyFilters(value, selectedQrCode, selectedCodeType);
  };

  const handleQrCodeChange = (event) => {
    const value = event.target.value;
    setSelectedQrCode(value);
    applyFilters(searchTerm, value, selectedCodeType);
  };

  const handleCodeTypeChange = (event) => {
    const value = event.target.value;
    setSelectedCodeType(value);
    applyFilters(searchTerm, selectedQrCode, value);
  };

  const applyFilters = (search, qrCode, codeType) => {
    const filtered = feedbacks.filter(
      (feedback) =>
        feedback.full_name?.toLowerCase().includes(search) &&
        (qrCode === "" || feedback.qrcode_name === qrCode) &&
        (codeType === "" || feedback.qrcode_type === codeType)
    );
    setFilteredFeedbacks(filtered);
  };

  const handleExport = async () => {
    try {
      const response = await api.post(export_feedback, {}, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.headers["content-type"] });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "exported_feedbacks.csv"; // Set the file name
      link.click();

      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error exporting feedbacks:", error);
    }
  };

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col md={6} lg={4}>
            <SearchBox
              key_name="searchTerm"
              value={searchTerm}
              onhandlechange={handleSearchChange}
            />
          </Col>
          <Col md={12} lg={8}>
            <Dropdown
              list={qrCodeNames}
              value={selectedQrCode}
              onhandlechange={handleQrCodeChange}
            />
            <Dropdown
              list={codeTypes}
              value={selectedCodeType}
              onhandlechange={handleCodeTypeChange}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="contained"
              className="btn"
              style={{
                backgroundColor: "white",
                color: "black",
                border: "1px solid black",
                borderRadius: "50px",
                float: "right",
              }}
              onClick={handleExport} // Attach the export function
            >
              <b>Export</b>
            </Button>
          </Col>
        </Row>
      </div>
      <AllList feedbacks={filteredFeedbacks} loading={loading}/>
    </React.Fragment>
  );
}

export default Feedback;
