import { Col, Row } from "react-bootstrap";
import SubHead from "../../../Component/Merchant/SubHead";
import Tabs from "../../../Component/Merchant/Tabs";
import { QrCodeTabs } from "./Utils/utils";
import { useState } from "react";
import createIcon from "../../../assets/image/Add.svg";
import { Link, useNavigate } from "react-router-dom";
import Allcode from "./AllCodes";
import StaticQRCode from "./Static";
import DynamicQRCode from "./Dynamic";
import FavoriteQRCode from "./Favorite";
import ArchivedQRCode from "./Archived";
import QRCodeAnalytics from "./Analytics";
import GroupQRCode from "./Group";
import { check_draft_status } from "../../../Service/ApiUrls";
import CreateGroupModal from "./Group/CreateGroup";
import api from "../../../Service/Axios"

function QRcode() {
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(1);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility


  const getSubHeadThird = () => {
    switch (activeId) {
      case 1:
        return "All Codes";
      case 2:
        return "Static Code";
      case 3:
        return "Dynamic Code";
      case 4:
        return "Favorite Code";
      case 5:
        return "Archived Codes";
      case 6:
        return "QR Analytics";
      case 7:
        return "Group Code";
      default:
        return "";
    }
  };
  const checkDraftStatus = async () => {
    try {
      const response = await api.get(check_draft_status);
      const status = response.data.data.draft_details.draft_status;
      if (!status) {
        navigate("/qrcode/createqr");
      } else {
        navigate(
          `/qrcode/draft/${response?.data?.data?.draft_details?.qrcode_id}`
        );
      } 
    } catch (error) {
      console.error("Error checking draft status:", error);
    }
  };
  return (
    <>
      <SubHead
        head="QR code"
        first="Home"
        second="QR Code"
        third={getSubHeadThird()}
      />
      <Row className="mt-2">
        <Col md={12} lg={8}>
          <Tabs
            values={QrCodeTabs}
            activeId={activeId}
            setActiveId={setActiveId}
          />
        </Col>
        {activeId === 1 && ( // Show the "Create New QR Code" button only for Allcode tab
          <Col md={12} lg={4} className="d-flex justify-content-end">
            <div className="create" onClick={checkDraftStatus}>
              <img src={createIcon} alt="Create New QR Code" />
              <p className="mt-3" style={{ fontWeight: "bold" }}>
                Create New QR Code
              </p>
            </div>
          </Col>
        )}
         {activeId === 7 && ( // Show the "Create Group" button only for GroupQRCode tab
          <Col md={12} lg={4} className="d-flex justify-content-end">
            <div className="create" onClick={() => setShowModal(true)}>
              <img src={createIcon} alt="Create Group" />
              <p className="mt-3" style={{ fontWeight: "bold" }}>
                Create Group
              </p>
            </div>
          </Col>
        )}
      </Row>
      {activeId === 1 && <Allcode />}
      {activeId === 2 && <StaticQRCode />}
      {activeId === 3 && <DynamicQRCode />}
      {activeId === 4 && <FavoriteQRCode />}
      {activeId === 5 && <ArchivedQRCode />}
      {activeId === 6 && <QRCodeAnalytics />}
      {activeId === 7 && <GroupQRCode />}
      <CreateGroupModal show={showModal} handleClose={() => setShowModal(false)} />

    </>
  );
}

export default QRcode;
